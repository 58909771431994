// @flow weak

import React from "react"
import { styled } from "@material-ui/core/styles"
import * as colors from "@material-ui/core/colors"
import styles from "./stats.module.css"


const Container = styled("div")({})
const Stat = styled("div")({
  display: "inline-flex",
  flexDirection: "column",
  margin: 8,
})
const Label = styled("div")({
  fontSize: 14,
  fontWeight: "bold",
  color: "#1515B8",
})
const Value = styled("div")({
  fontSize: 32,
  marginTop: 8,
})

export default ({ stats }) => {
  return (
    // <section className={styles.statsContainer}>
    //   <div className={styles.statsWrapper}>
    //     <div className={styles.stateItem}>
    //       <h3 className={styles.statsTitle}>Labeled Samples</h3>
    //       <p className={styles.statsNumber}>25</p>

    //     </div>



    //   </div>


    // </section>
    <section className={styles.statsWrapper}>
      {stats.map((s) => (
        <div className={s.name === 'Labeled Samples' ? styles.stateItemActive : styles.stateItem} key={s.name}>
          <h3 className={styles.statsTitle}>{s.name}</h3>
          <p className={styles.statsNumber}> {s.value}</p>
        </div>
      ))}
    </section>
  )
}
