import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    root: {
        padding: 50,
        textAlign: "center",
    },
    title: {
        margin: 50,
    },
    explain: {},
});
