function projectTypeSelected(
    projectStatus,
    setChooseLabelOpen,
    mode,
    changeMode,
) {
    // tested
    if (
        (!projectStatus.iface.type || projectStatus.iface.type === "empty")
    && (mode === "label" || mode === "upload")
    ) {
    // prevent the user from entering the label and upload screen
    // before choosing the labeling type.
        setChooseLabelOpen(true);
        changeMode("configuration");
        return false;
    }
    return true;
}

function projectHasAtLeast2Labels(
    projectStatus,
    setMinLabelsOpen,
    mode,
    changeMode,
) {
    // tested
    if (
        projectStatus.iface
    && projectStatus.iface.labels
    && projectStatus.iface.labels.length < 2
    && (mode === "label" || mode === "upload")
    ) {
        // force the user to put  2 labels or more for the project.
        setMinLabelsOpen(true);
        changeMode("configuration");
        return false;
    }
    return true;
}

function labelingCardinalitySelected(
    projectStatus,
    setAnswerLabelingQuestion,
    mode,
    changeMode,
) {
    // tested
    if (
        !projectStatus.iface.hasOwnProperty("multiple")
        && projectStatus.iface.type === "image_classification"
        && (mode === "label" || mode === "upload")
    ) {
    // force the user to choose labeling type (multiple, single) per image
        setAnswerLabelingQuestion(true);
        changeMode("configuration");
        return false;
    }
    return true;
}

function areLabelsUnique(projectStatus, setDuplicateLabels, mode, changeMode) {
    // force user to put unique labels
    if (!(mode === "label" || mode === "upload")) return true;

    const UniqueLabels = new Set();
    const { labels } = projectStatus.iface;
    for (const label of labels) {
        if (typeof label === "object") UniqueLabels.add(label.id);
        else UniqueLabels.add(label);
    }

    if (UniqueLabels.size !== labels.length) {
    // could be mode !='configuration'
        setDuplicateLabels(true);
        changeMode("configuration");
        return false;
    }
    return true;
}

function areDetectionQuestionsAnswered(
    projectStatus,
    setDetectionQuestionsNotAnswered,
    mode,
    changeMode,
) {
    if (
        !(mode === "label" || mode === "upload")
    || projectStatus.iface.type !== "image_segmentation"
    ) return true;

    const q1 = projectStatus.iface.multipleRegions;
    const q2 = projectStatus.iface.multipleRegionLabels;
    if (q1 === undefined || q2 === undefined) {
        setDetectionQuestionsNotAnswered(true);
        changeMode("configuration");
        return false;
    }
    return true;
}

export function configurationGuard(
    projectStatus,
    // the object bellow has setter functions
    // to change state variables
    stateVarSetters,
    mode,
    changeMode,
) {
    /**
   * theses function will take action to prevent the user from doing invalid operations
   * and then return false to let the user process on error message at a time.
   */
    const {
        setChooseLabelOpen,
        setMinLabelsOpen,
        setDuplicateLabels,
        setAnswerLabelingQuestion,
    } = stateVarSetters;

    if (!projectTypeSelected(projectStatus, setChooseLabelOpen, mode, changeMode)) return false;
    if (
        !projectHasAtLeast2Labels(projectStatus, setMinLabelsOpen, mode, changeMode)
    ) return false;
    if (!areLabelsUnique(projectStatus, setDuplicateLabels, mode, changeMode)) return false;
    if (
        !labelingCardinalitySelected(
            projectStatus,
            setAnswerLabelingQuestion,
            mode,
            changeMode,
        )
    ) return false;
    return true;
}
