// @flow weak

import React, { memo, useContext } from "react"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import { makeStyles } from "@material-ui/core/styles"
import SettingsIcon from "@material-ui/icons/Settings"
import StorageIcon from "@material-ui/icons/Storage"
import BorderColorIcon from "@material-ui/icons/BorderColor"
import urlJoin from "url-join";
import styles from './header.module.css'
import { UserContext } from "../UserContext"


const useStyles = makeStyles((theme) => ({
  headerButton: {
    marginLeft: 16,
  },
  grow: { flexGrow: 1 },
  list: {
    width: 300,
  },
  tab: {
    color: "#1515B8",
    "& .icon": {},
  },
  fullHeightTab: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      minWidth: 20,
    },
  },
  tabWrapper: {
    padding:20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textTransform: "none",
    color: "#1515B8",
    fontSize:18,
    "&&& svg": {
      marginBottom: 0,
      marginRight: 8,
      width: 28,
      height: 28,
    },
  },
}))
// ["", "", ""]
const getIcon = (t) => {
  switch (t) {
    case "configuration":
      return <SettingsIcon className="icon" />
    case "upload":
      return <StorageIcon className="icon" />
    case "label":
      return <BorderColorIcon className="icon" />

    default:
      return <div></div>
  }
}

const HeaderToolbar = ({
  tabs,
  currentTab,
  onChangeTab,
  additionalButtons,
  onOpenDrawer,
  title = "Tuba",
  fileOpen,
  // sessionBoxOpen,
  // changeSessionBoxOpen,
  // inSession,
  // onCreateSession,
  // onLeaveSession,
  // onJoinSession,
  onDownload,
  // selectedBrush,
  // onChangeSelectedBrush,
  isSmall,
  changeLoginDrawerOpen,
  // collaborateError,
  isWelcomePage,
}) => {
  const c = useStyles()
  // const { authProvider, isLoggedIn, logout } = useAuth()
  
  let userContext = useContext(UserContext);

  return (
    <AppBar style={{ backgroundColor: "#fff" }} position="static">
      <Toolbar  >
        {fileOpen ? <b className={styles.tubaHeader}>Tuba</b> : <b className={styles.tubaHeader}>Tuba</b> }
        <div className={c.grow} />
        {additionalButtons}
        {tabs.length > 0 && (
          <Tabs
            onChange={(e, newTab) => {
                if(newTab === 'Home'){
                  window.location.href = urlJoin(process.env.REACT_APP_IAM, "projects");
                }
                // ENABLE IMAGE POINTS
                // else if (newTab === 'imagepoints') {
                //   window.location.href = urlJoin(process.env.REACT_APP_IAM, "billing");
                // }

                if(newTab !=='Home') // in case we clicked cancle.
                  onChangeTab(newTab.toLowerCase());
              }
            }
            value={currentTab}  >
            
            {/* home page tab*/}
            <Tab
            className={styles.tab}
            label='Home'
            value="Home"/>

            {/* ENABLE IMAGE POINTS */}
            {/* {
              userContext &&
                <Tab
                className={styles.tab}
                label={`${userContext.imagePointDetails.total} points`}
                value="imagepoints"/>
            } */}

            {tabs.map((t) => (
              <Tab
                key={t}
                classes={{ root: c.fullHeightTab, wrapper: c.tabWrapper }}
                className={c.tab}
                icon={getIcon(t)}
                label={isSmall ? "" : t}
                value={t.toLowerCase()}
              />
            ))}
          </Tabs>
        )}
        {/* {authProvider !== "none" && isWelcomePage && !isLoggedIn && (
          <Button
            onClick={() => {
              changeLoginDrawerOpen(true)
            }}
            className={c.headerButton}
          >
            Login with {capitalize(authProvider)}
          </Button>
        )}
        {isLoggedIn && (
          <Button onClick={logout} className={c.headerButton}>
            Logout
          </Button>
        )} */}
      </Toolbar>
    </AppBar>
  )
}

export default memo(HeaderToolbar)
