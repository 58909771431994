// @flow

import React, { useMemo } from "react";
import Box from "@material-ui/core/Box";
import Workspace from "react-material-workspace-layout/Workspace";
import { ZoomIn, ZoomOut } from "@material-ui/icons";

import { styled } from "@material-ui/core/styles";
import Crop32Icon from "@material-ui/icons/Crop32";
import TimelineIcon from "@material-ui/icons/Timeline";
import styles from "./workSpace.module.css"

const HeaderLeftSideContainer = styled("div")({
    fontSize: 22,
    fontWeight: "bold",
    color: "#302ECB"
});

const WorkspaceContainer = ({
    children,
    onNext,
    onPrev,
    currentSampleIndex = 0,
    numberOfSamples = 1,
    globalSampleIndex = 1,
    titleContent,
    onClickHeaderItem,
    leftsideBarIcons,
    onZoomIn,
    onZoomOut,
}) => {
    const headerItems = useMemo(
        () => [
            { name: "Zoom +", icon: <ZoomIn className={styles.svgIconFill} />, onClick: onZoomIn },
            { name: "Zoom -", icon: <ZoomOut className={styles.svgIconFill} />, onClick: onZoomOut },
            (currentSampleIndex > 0 || onPrev) && { name: "Prev", onClick: onPrev },
            (numberOfSamples > currentSampleIndex + 1 || onNext) && {
                name: "Next",
                onClick: onNext,
            },
            { name: "Review" },
        ].filter(Boolean),
        [currentSampleIndex, numberOfSamples, onNext, onPrev],
    );

    return (
        <Workspace
            headerLeftSide={
                titleContent === undefined ? (
                    <Box

                        paddingLeft={2} fontWeight="bold">
                        <HeaderLeftSideContainer>
                            Sample
                            {" "}
                            {numberOfSamples > 1
                                ? `${globalSampleIndex + 1} / ${numberOfSamples}`
                                : `${globalSampleIndex + 1}` /* Why this case? */}
                        </HeaderLeftSideContainer>
                    </Box>
                ) : (
                    titleContent
                )
            }
            onClickHeaderItem={onClickHeaderItem}
            iconDictionary={{ rect: Crop32Icon, polygon: TimelineIcon }}
            headerItems={headerItems}
            iconSidebarItems={leftsideBarIcons || []}
            rightSidebarItems={[]}
        >
            {/* <Box padding={2} style={{ width: "100%", height: "100%" }}> */}
            {children}
            {/* </Box> */}
        </Workspace>
    );
};

export default WorkspaceContainer;
