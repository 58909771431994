import {setIn} from "seamless-immutable";
import duration from "duration"


export function deleteSample(sampleIndex, dataset)
{
    /**
     * sample: sampleIndex to be deleted from dataset.samples 
     * datset: current data set used in the tool.
     * 
     * return: new Immutable datasete without the sampel we wanted to delete
     *         this will be the new data set used accross all the tool.
     */

    if(dataset.samples.length == 0) 
        return  ;
    if(sampleIndex >= dataset.samples.length)
        throw new Error("sample index >= samples array length");
    

    const filteredSamples = dataset.samples.filter((sample, index)=> index !=sampleIndex);
    const newDataSet =  setIn(dataset, ['samples'], filteredSamples);
    return newDataSet; 
}

export function unLabelSample(sampleIndex, dataset)
{
    /**
     * sample of the index to be unlabeled
     * dataset : current DataSet
     * 
     * return: new Data set with sample at sampleIndex unLabeled.
     */

    if(dataset.samples.length == 0) 
        return ;
    if(sampleIndex >= dataset.samples.length ||
         !dataset.samples[sampleIndex].annotation) // labled image.
        throw new Error("sample index >= samples array length || there is no label || labeled Image");

    let newDataset = setIn(dataset, ['samples', sampleIndex.toString(), 'annotation'], null);
    console.log("new Data set is : ", newDataset);
    return newDataset;
}


export function getStatistics(dataset, percentComplete, sampleTimeToComplete)
{
     return [
              {
                name: "Labeled Samples",
                value: dataset.samples
                  .map((s) => s.annotation !== undefined && s.annotation !== null)
                  .filter(Boolean).length,
              },
              
              {
                name: "Unlabeled Samples",
                value: dataset.samples.length - dataset.samples
                  .map((s) => s.annotation !== undefined && s.annotation !== null)
                  .filter(Boolean).length,
              },
              
              {
                name: "Samples Number ",
                value: dataset.samples.length,
              },
              
              {
                name: "Percent Complete",
                value: Math.floor(percentComplete * 100) + "%",
              },
              
              {
                name: "Time per Sample",
                value: duration(
                  new Date(Date.now() - sampleTimeToComplete)
                ).toString(1, 1),
              },
              
              {
                name: "Estimated Remaining",
                value: duration(
                  new Date(
                    Date.now() -
                    sampleTimeToComplete *
                    (1 - percentComplete) *
                    (dataset.samples || []).length
                  )
                ).toString(1, 2),
              },
    ]
}