import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const Container = styled("div")({

    padding: 24,
    // textAlign:'center',
    // color: "#302ECB",
    "&.emptyState": {
        textAlign: "center",
        backgroundColor: "#fff",
        minHeight: "70vh",
        padding: 64,
        "& .bigText": {
            textAlign: "left",
            fontSize: 36,
            color: "#00005D",
            fontWeight: "bold",
            marginBottom: 30,
        },
    },
});

export const NavStripButton = styled(Button)({
    margin: 8,
    "& .icon": {

        marginRight: 8,
        color: "#888",
    },
    alignItems: "center",
    justifyContent: "center",
    "&.selected": {
        backgroundColor: "#00005D",
        color: "#fff",
        "& .icon": {
            color: "#fff",
        },
    },
});
