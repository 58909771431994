import urlJoin from "url-join";
import download from "in-browser-download";

export async function getSubscriptionDetails(userId) {
    const subscriptionDetailsResponse = await fetch(urlJoin(process.env.REACT_APP_BACKEND, 'subscription-details'),
    {
        body: JSON.stringify({user_id: userId}),
        method: 'POST',
        headers: {"Content-Type": "application/json"}
    });
    const subscription = await subscriptionDetailsResponse.json();
    return subscription;
}

// ENABLE IMAGE POINTS
export async function getImagePointDetails(userId) {
    const imagePointDetailsResponse = await fetch(
        urlJoin(process.env.REACT_APP_BACKEND, 'user', userId, 'image_points'),
        { method: 'GET' });
    const imagePointDetails = await imagePointDetailsResponse.json();
    if (imagePointDetails.error) throw new Error(imagePointDetails.error);
    return imagePointDetails;
}

// ENABLE IMAGE POINTS
export async function consumeImagePoints(userId, imagePointCount) {
    const consumeImagePointsResponse = await fetch(urlJoin(
        process.env.REACT_APP_BACKEND, 'user', userId, 'image_points', 'consume'),
    {
        body: JSON.stringify({image_point_count: imagePointCount}),
        method: 'POST',
        headers: {"Content-Type": "application/json"}
    });
    const consumeImagePointsJson = await consumeImagePointsResponse.json();
    if (consumeImagePointsJson.error) throw new Error(consumeImagePointsJson.error);
    return consumeImagePointsJson.image_point_total;
}

export async function getStorageUsage(userId) {
    const storageUsageResponse = await fetch(urlJoin(process.env.REACT_APP_BACKEND, 'storage-usage'),
    {
        body: JSON.stringify({user_id: userId}),
        method: 'POST',
        headers: {"Content-Type": "application/json"}
    });
    const currentStorageUsage = await storageUsageResponse.json();
    return currentStorageUsage;
}

export async function downloadDatasetAsCoco(pid, dataset) {
    await prepareDataset(pid, dataset);

    const outputName = `${pid}_data_coco.zip`;

    const response = await fetch(urlJoin(process.env.REACT_APP_BACKEND, "convert_to_coco"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            id: pid,
            type: dataset.interface.type,
            data: dataset.samples,
        }),
    });
    const data = await response.blob();
    download(data, outputName);
}

export async function prepareDataset(pid, dataset) {
    // TODO: Remove this endpoint
    const dataToSend = {
        dataset: {
            id: pid,
            type: dataset.interface.type,
            data: dataset.samples,
        },
        labels: dataset.interface.labels
    };

    return await fetch(urlJoin(process.env.REACT_APP_BACKEND, "prepare"), {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToSend),
        mode: "no-cors",
    })
}