// @flow

import React, { useState, useEffect, useMemo, useRef } from "react";
import useEventCallback from "use-event-callback";
import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import without from "lodash/without";
import WorkspaceContainer from "../WorkspaceContainer";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import styles from "./imgClassification.module.css";

const letters = "abcdefghijklmnopqrstuvwxyz1234567890".split("");

const Container = styled("div")({
    maxWidth: "100vw",
    display: "flex",
    flexDirection: "row",
    height: "500px",
    // backgroundColor:"#555",
});

// theses 2 are responsible for the image display.
const ImageContainer = styled("div")({
    position: "relative",
    display: "flex",
    flexGrow: 1,
    // backgroundColor:"#222"
});

const ButtonsContainer = styled("div")({
    paddingLeft: 50,
    paddingRight: 50,
    marginTop: 8,
    textAlign: "center",
    flexShrink: 0,
    width: "30%",
    height: "100%",
    overflowY: "auto",
    // backgroundColor: "aqua",
});

const CheckButton = styled(Button)({
    margin: 8,
    color: "#fff",
    fontSize: 18,
    paddingRight: 16,
    transition: "transform 50ms ease",
});

const [emptyObj, emptyArr] = [{}, []];

const ImageClassification = ({
    sampleIndex: globalSampleIndex,
    interface: iface,
    samples = emptyArr /** samples is empty, it is not passed to us from any previous component */,
    containerProps = emptyObj,
    onSaveTaskOutputItem,
    numberOfSamples,
}) => {
    // TODO remove legacy "availableLabels" support
    if (iface.availableLabels && !iface.labels) {
        iface.labels = iface.availableLabels;
    }

    if (!iface.labels)
        throw new Error(
            "No labels defined. Add some labels in Setup to continue."
        );
    const [sampleIndex, changeSampleIndex] = useState(0);
    const [enlargedLabel, changeEnlargedLabel] = useState(null);
    const [currentOutput, changeCurrentOutput] = useState(emptyArr);
    const labels = useMemo(
        () =>
            iface.labels.map((l) =>
                typeof l === "string" ? { id: l, description: l } : l
            ),
        [iface.labels]
    );

    const onDone = useEventCallback(async () => {
        if (containerProps.onExit) {
            await saveOutput(sampleIndex, currentOutput);
            containerProps.onExit();
        }
    });

    const goToNext = () => {
        if (sampleIndex !== samples.length - 1) {
            changeSampleIndex(sampleIndex + 1);
        } else if (containerProps.onExit) containerProps.onExit("go-to-next");
    };

    const saveOutput = async (sampleIndex, newOutput) => {
        console.log("🚀 ~ saveOutput ~ sampleIndex:", sampleIndex);
        let outputToBeSaved = newOutput;
        if (Array.isArray(outputToBeSaved)) {
            if (outputToBeSaved.length === 1) {
                outputToBeSaved = outputToBeSaved[0];
            } else if (outputToBeSaved.length === 0) {
                outputToBeSaved = undefined;
            }
        }

        await onSaveTaskOutputItem(sampleIndex, outputToBeSaved);

        console.log("🚀 ~ saveOutput ~ outputToBeSaved:", outputToBeSaved);
    };

    const onNext = useEventCallback(async (newOutput) => {
        try {
            await saveOutput(sampleIndex, newOutput);
        } finally {
            // Go to next image anyway
            goToNext();
        }
    });

    const onPrev = useEventCallback(() => {
        if (sampleIndex > 0) {
            changeSampleIndex(sampleIndex - 1);
        } else if (containerProps.onExit)
            containerProps.onExit("go-to-previous");
    });

    useEffect(() => {
        const timeout = setTimeout(() => {
            changeEnlargedLabel(null);
        }, 100);
        return () => clearTimeout(timeout);
    }, [enlargedLabel]);

    const onClickLabel = useEventCallback(async (label) => {
        console.log("🚀 ~ onClickLabel ~ label:", label);
        changeEnlargedLabel(label);
        let newOutput;
        if ((currentOutput || []).includes(label.id)) {
            newOutput = without(currentOutput, label.id);
        } else if (iface.multiple) {
            newOutput = currentOutput.concat([label.id]);
        } else {
            newOutput = label.id;
        }
        console.log("new output", newOutput);

        changeCurrentOutput(newOutput);
        if (!iface.multiple) {
            try {
                await saveOutput(sampleIndex, newOutput);
                console.log("saved");
            } catch (err) {
                return;
            }
            // Go to the next page if no error occurs
            goToNext();
        }
    });

    useEffect(() => {
        let newOutput = samples[sampleIndex].annotation;
        if (!newOutput) newOutput = [];
        if (typeof newOutput === "string") newOutput = [newOutput];
        changeCurrentOutput(newOutput);
    }, [sampleIndex, globalSampleIndex, samples]);

    const [hotkeyMap, labelKeyMap] = useMemo(() => {
        const hotkeyMap = {
            enter: onDone,
            arrowright: onNext,
            arrowleft: onPrev,
        };
        const labelKeyMap = {};
        for (const label of labels) {
            const nextAvailableLetter = label.id
                .split("")
                .filter((l) => letters.includes(l))
                .find((l) => !hotkeyMap[l.toLowerCase()]);
            if (!nextAvailableLetter) continue;
            hotkeyMap[nextAvailableLetter] = () => onClickLabel(label);
            labelKeyMap[label.id] = nextAvailableLetter;
        }
        return [hotkeyMap, labelKeyMap];
    }, [labels, onClickLabel, onDone, onNext, onPrev]);

    useEffect(() => {
        const onKeyDown = (e) => {
            const key = e.key.toLowerCase();
            if (hotkeyMap[key]) {
                hotkeyMap[key]();
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [hotkeyMap]);

    const zoomWrapper = useRef(null);

    const zoomIn = () => {
        if (zoomWrapper.current) zoomWrapper.current.zoomIn(1);
    };

    const zoomOut = () => {
        if (zoomWrapper.current) zoomWrapper.current.zoomOut(1);
    };

    return (
        <>
            <section className={styles.classificationWrapper}>
                <WorkspaceContainer
                    onNext={() => {
                        /** this is made in this way as WorkspaceContainer
                pass unExpected parameters to onNext call back function
                 */
                        onNext(currentOutput);
                    }}
                    onPrev={onPrev}
                    onClickHeaderItem={onDone}
                    numberOfSamples={numberOfSamples}
                    currentSampleIndex={sampleIndex}
                    globalSampleIndex={globalSampleIndex}
                    onZoomIn={zoomIn}
                    onZoomOut={zoomOut}
                >
                    <Container
                    // style={{
                    //     height: containerProps.height || "calc(100% - 70px)",
                    //     minHeight: 600,
                    // }}
                    >
                        <ImageContainer>
                            <TransformWrapper
                                ref={zoomWrapper}
                                panning={{ activationKeys: ["Shift"] }}
                            >
                                <TransformComponent
                                    wrapperStyle={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                    contentStyle={{
                                        width: "100%",
                                        height: "100%",
                                    }}
                                >
                                    <img
                                        className="detection-img"
                                        src={samples[sampleIndex].imageUrl}
                                    />
                                </TransformComponent>
                            </TransformWrapper>
                        </ImageContainer>
                        <ButtonsContainer>
                            {labels.map((label) => (
                                <>
                                    <CheckButton
                                        key={label.id}
                                        onClick={() => onClickLabel(label)}
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#00005D",
                                            transform:
                                                enlargedLabel === label
                                                    ? "scale(1.1,1.1)"
                                                    : undefined,
                                        }}
                                        className={styles.btnPrimary}
                                    >
                                        <Checkbox
                                            style={{ color: "#fff" }}
                                            checked={currentOutput.includes(
                                                label.id
                                            )}
                                        />
                                        {label.id}
                                        {labelKeyMap[label.id]
                                            ? ` (${labelKeyMap[label.id]})`
                                            : ""}
                                    </CheckButton>
                                    <br />
                                </>
                            ))}
                        </ButtonsContainer>
                    </Container>
                </WorkspaceContainer>
            </section>
        </>
    );
};

export default ImageClassification;
