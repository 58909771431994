import React, { useEffect } from "react";

import { templates } from "./templates";
import { useStyles } from "./Styles";

const StartingPage = ({ onOpenTemplate }) => {
    const c = useStyles();
    useEffect(() => {
        onOpenTemplate(templates.find((t) => t.name === "Empty"));
    }, [onOpenTemplate]);

    return (
        <div className={c.container} />
    );
};

export default StartingPage;
