import React, { useCallback, useState, useMemo } from "react";
import { styled } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import { useDropzone } from "react-dropzone";
import {
    getValidImageExt,
    valid_file_extension,
} from "../../utils/file_validation";
import { ErrorDialog } from "../helpers/material_ui_helpers/ErrorDialog";
import { StylesContext } from "@material-ui/styles";
import styles from "./multiFileDrop.module.css";

const Container = styled(Box)({
    width: 400,
    height: 400,
    padding: 20,
    border: `2px dotted ${colors.grey[400]}`,
    textAlign: "center",
    fontSize: 24,
    cursor: "pointer",
});

const FileCounter = styled(Box)({
    fontSize: 24,
    color: colors.grey[700],
    fontWeight: "bold",
});

/*
  This component allows you to upload multiple files, then reads the files in
  serial. It's perfect for an uploading process where you don't want to upload one
  file at a time. It gives a progress bar indicating upload progres.
*/

export const MultiFileDrop = ({ loadFile, onComplete }) => {
    const [totalUploaded, setTotalUploaded] = useState(0);
    const [errors, setErrors] = useState([]);

    const [fileRejectionsErrors, set_fileRejectionsErrors] = useState(null);
    const [rejectionError, set_rejectionError] = useState(false);

    const [{ totalFiles }, setUploadStartState] = useState({
        filesSelected: false,
    });
    const onDrop = useCallback(async (acceptedFiles) => {
        /**
         * acceptedFiles -- uploadedFiles
         * this code is responsible for uploading imag/es.
         */

        console.log(acceptedFiles);
        setUploadStartState({
            totalFiles: acceptedFiles.length,
            filesSelected: true,
        });

        const errors = [];
        for (const [index, file] of acceptedFiles.entries()) {
            await loadFile(file).catch((err) => {
                console.log(err);
                errors.push(err.toString());
                setErrors(errors);
            });

            setTotalUploaded(index + 1);
        }
        onComplete();
        // eslint-disable-next-line
    }, []);

    const onDropRejected = (fileRejections) => {
        // generate the error for the dialog.

        const { length } = fileRejections;
        const ref = length > 1 ? "Each" : "it";

        let tmpError = `Cannot Upload File${length > 1 ? "s" : ""}\n`;
        for (const f of fileRejections) { tmpError += `\n${f.name}`; }
        tmpError += `\n \n ${ref} may exceed 20 MB or may be in invalid format, \n supported image formats are (PNG, JPG, JPEG, BMP)`;

        set_fileRejectionsErrors(tmpError);
        set_rejectionError(true);
    };

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        isDragAccept,
    } = useDropzone({
        onDrop,
        accept: getValidImageExt(), // 'image/png, image/jpg, image/jpeg, image/bmp,',
        minSize: 0,
        maxSize: 20000000,
        onDropRejected,
    });

    const baseStyle = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "10px",
        borderWidth: 1,
        borderRadius: 8,
        borderColor: "#1515b8",
        borderStyle: "dashed",
        backgroundColor: "#1515b812",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
        marginBottom: "2rem",
    };

    const activeStyle = {
        borderColor: "#2196f3",
    };

    const acceptStyle = {
        borderColor: "#00e676",
    };

    const rejectStyle = {
        borderColor: "#ff1744",
    };
    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [
            baseStyle,
            isDragActive,
            activeStyle,
            isDragAccept,
            acceptStyle,
            isDragReject,
            rejectStyle,
        ]
    ); // deepscan-disable-line

    return (
        <>
            <ErrorDialog
                open={rejectionError}
                setOpen={set_rejectionError}
                title="Upload Errors"
                content={fileRejectionsErrors}
                onApproval={() => {
                    set_fileRejectionsErrors("");
                }}
            />

            <Container {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <FileCounter className={StylesContext.dropFile}>
                    {totalUploaded} / {totalFiles} Files Processed
                </FileCounter>

                <div className={styles.uploadBox}></div>

                <div className={styles.uploadContentTitle}>
                    <div className={styles.svgDownloadHeader}>
                        <svg
                            width="65"
                            height="65"
                            viewBox="0 0 85 74"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M44.1702 17.2124C44.0058 17.7636 44.3198 18.3429 44.8707 18.5071L44.9634 18.5348L44.9668 18.5309C45.4896 18.6348 46.0122 18.3243 46.1658 17.8059C47.5969 12.9979 52.1054 9.63925 57.1286 9.63925C57.7036 9.63925 58.1699 9.17301 58.1699 8.59814C58.1699 8.02325 57.7035 7.55703 57.1286 7.55703C50.9878 7.55703 45.8272 11.645 44.1702 17.2124ZM44.1702 17.2124L44.3139 17.2553M44.1702 17.2124C44.1702 17.2124 44.1702 17.2124 44.1702 17.2125L44.3139 17.2553M44.3139 17.2553C44.1732 17.7269 44.4419 18.2227 44.9136 18.3634L44.3139 17.2553Z"
                                fill="#1515B8"
                                stroke="#F9FFF9"
                                strokeWidth="0.3"
                            />
                            <path
                                d="M69.6052 52.2625H64.2579C63.7658 52.2625 63.3666 51.8634 63.3666 51.3714C63.3666 50.8794 63.7658 50.4803 64.2579 50.4803H69.6052C76.9763 50.4803 82.9737 44.4838 82.9737 37.1138C82.9737 29.7438 76.9763 23.7473 69.6052 23.7473H69.4766C69.2182 23.7473 68.9724 23.6353 68.8031 23.4399C68.6338 23.2445 68.5574 22.9854 68.5943 22.7295C68.6739 22.1745 68.714 21.6169 68.714 21.074C68.714 14.6867 63.5161 9.48963 57.1279 9.48963C54.6426 9.48963 52.2726 10.2662 50.2736 11.7358C49.8344 12.0585 49.2105 11.9153 48.9565 11.4322C43.2953 0.653756 28.5089 -0.79368 20.8428 8.58263C17.6134 12.5327 16.3446 17.6712 17.3613 22.6793C17.4734 23.2324 17.05 23.748 16.4879 23.748H16.1308C8.75973 23.748 2.76233 29.7445 2.76233 37.1145C2.76233 44.4845 8.75973 50.481 16.1308 50.481H21.4781C21.9702 50.481 22.3694 50.8801 22.3694 51.3721C22.3694 51.8641 21.9702 52.2632 21.4781 52.2632H16.1308C7.77671 52.2632 0.979782 45.4673 0.979782 37.1144C0.979782 28.9959 7.40041 22.3482 15.4312 21.9816C14.6768 16.7858 16.1193 11.5448 19.4627 7.45477C27.6703 -2.58473 43.3995 -1.45944 50.0486 9.73534C52.1698 8.40568 54.597 7.70814 57.1276 7.70814C64.8673 7.70814 71.0003 14.2947 70.4636 21.9899C78.4204 22.4361 84.7558 29.0487 84.7558 37.1138C84.7558 45.4673 77.9589 52.2625 69.6048 52.2625L69.6052 52.2625Z"
                                fill="#1515B8"
                            />
                            <path
                                d="M20.3174 50.8686C20.3174 63.2355 30.3799 73.2964 42.7485 73.2964C55.1171 73.2964 65.1795 63.2353 65.1795 50.8686C65.1795 38.5017 55.1171 28.4408 42.7485 28.4408C30.3798 28.4408 20.3174 38.5018 20.3174 50.8686ZM22.4003 50.8686C22.4003 39.6506 31.5286 30.5234 42.7485 30.5234C53.9681 30.5234 63.0966 39.6504 63.0966 50.8686C63.0966 62.0866 53.9681 71.2137 42.7485 71.2137C31.5288 71.2137 22.4003 62.0867 22.4003 50.8686Z"
                                fill="#1515B8"
                                stroke="#F9FFF9"
                                strokeWidth="0.3"
                            />
                            <path
                                d="M42.3533 59.8363C42.3533 60.2794 42.7127 60.6387 43.1558 60.6387C43.5988 60.6387 43.9583 60.2799 43.9583 59.8363V42.8741C43.9583 42.431 43.5989 42.0717 43.1558 42.0717C42.7127 42.0717 42.3533 42.431 42.3533 42.8741V59.8363Z"
                                fill="#1515B8"
                                stroke="#1515B8"
                                strokeWidth="0.3"
                            />
                            <path
                                d="M47.8081 48.6626C47.9646 48.819 48.1706 48.8976 48.3755 48.8976L38.3973 48.5565L38.5033 48.6626L38.5034 48.6625L43.1557 44.0109L47.8081 48.6626ZM43.7232 42.3087C43.4097 41.9954 42.9014 41.9952 42.5882 42.3087L48.3756 48.8976C48.5803 48.8976 48.7864 48.8197 48.9432 48.6624C49.2565 48.3489 49.2564 47.8412 48.943 47.5278L43.7232 42.3087Z"
                                fill="#1515B8"
                                stroke="#1515B8"
                                strokeWidth="0.3"
                            />
                        </svg>
                    </div>
                    <p className={styles.dragTitle} >Drag files here</p>
                    <p className={styles.clickBrowse}>or, click to browse (20 MB max each)</p>
                </div>
                {/* <em>(images will be accepted with 20 MB max size of each)</em> */}
                <p className={styles.allowedFormates}>
                    Valid Image Types: {valid_file_extension.join(", ")}
                </p>

                {
                    <Box
                        maxHeight={100}
                        style={{ overflowY: "auto" }}
                        textAlign="left"
                        color="red"
                        fontSize={12}
                    >
                        {
                            errors.length > 0
                                ? "Failed to upload the image"
                                : null
                            /* errors.map((err, i) => (
          <div key={i}>{err}</div>
        )) */
                        }
                    </Box>
                }
            </Container>
        </>
    );
};

export default MultiFileDrop;
