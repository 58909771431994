// @flow weak

import React from "react";

import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";

import ImportPage from "../ImportPage";
import {
    Container, SampleCounter,
} from "./Styled";

export const SamplesView = ({
    file,
    dataset, // who pass this to me
    projectStatus,
    saveProjectStatus,
    onChangeDataset,
    onChangeFile,
    authConfig,
    user,
}) => {
    const changeTab = (tab) => {
        // Prevents the upload tab from displaying any thing but the upload page.
        window.localStorage.lastSampleTab = tab;
    };

    return (
        <Container>
            <Box display="flex">
                {/** this tabs component is different form the tabs in the header */}
                <Tabs
                    value="import"
                    onChange={(e, newTab) => changeTab(newTab)}
                />

                <SampleCounter>
                    {(dataset.samples || []).length}
                    {" "}
                    Samples
                    <br />
                    {
                        (dataset.samples || []).filter((s) => s.annotation)
                            .length
                    }
                    {" "}
                    Labels
                </SampleCounter>
            </Box>
            <Box paddingTop={2} />
            <Box flexGrow={1}>
                <ImportPage
                    file={file}
                    projectStatus={projectStatus}
                    saveProjectStatus={saveProjectStatus}
                    onChangeFile={(file) => onChangeFile(file)}
                    onImportPageShouldExit={() => changeTab("grid")} // not used inside SampleView.
                    onChangeDataset={(newOHA) => onChangeDataset(newOHA)}
                    dataset={dataset}
                    authConfig={authConfig}
                    user={user}
                />
            </Box>
        </Container>
    );
};
export default SamplesView;
