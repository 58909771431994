// @flow

import React from "react";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./Styles";

export default ({ title, description, children }) => {
    const c = useStyles();
    return (
        <div className={c.root}>
            <Typography variant="h4" component="div" className={c.title}>
                {title}
            </Typography>
            <Typography variant="h6" component="div" className={c.explain}>
                {description || children}
            </Typography>
        </div>
    );
};
