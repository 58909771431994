// @flow
import React, { useMemo, useEffect } from "react";
import Survey from "material-survey/components/Survey";
import { setIn } from "seamless-immutable";

import { rtdb } from "../../firebase/firebase";
import { Container } from "../Styled";
import styles from "./classification.module.css"


const form = {
    questions: [
        {
            name: "labels",
            title: "Add Labels",
            description:
                "If you're labeling regions on an image, these are the allowed classifications or tags.",
            type: "matrixdynamic",
            columns: [
                { cellType: "text", name: "id", title: "Labels" },
                {
                    cellType: "text",
                    name: "description",
                    title: "Description (optional)",
                },
            ],
        },
    ],
};

export const ConfigureImageSegmentation = ({
    iface,
    projectStatus,
    onChange,
    userId,
    projectId,
    savedLabels,
}) => {
    const projectRef = rtdb.ref(`${userId}/${projectId}`);
    projectRef.child("classification_type").set("image_segmentation");

    const defaultAnswers = useMemo(
        () => ({
            labels:
                (iface.labels || savedLabels || []).map((a) => (typeof a === "string" ? { id: a, description: a } : a)) || [],
        }),
        [iface.labels],
    );

    let filteredNewValues = [];
    return (
        <Container className={styles.containerWrapp} >
            <Survey
                noActions
                variant="flat"
                defaultAnswers={defaultAnswers}
                // When is this called?
                onQuestionChange={(questionId, newValue, answers) => {
                    /**
                    newValue hold the data in the form weather it is valid or not.
                    this call is responsible
                    to set the changes from the form
                    to the iface.
                    */

                    if (questionId === "labels") {
                        for (const val of newValue) {
                            if (val.id && val.id.trim() !== "") {
                                const newValue = {
                                    id: val.id.trim(),
                                    description: val?.description?.trim(),
                                };
                                if (newValue.description === undefined) {
                                    newValue.description = null;
                                }
                                filteredNewValues.push(newValue);
                            }
                        }
                    } else {
                        filteredNewValues = newValue;
                    }
                    /**
                     * setIn will make new object like iface
                     * with the specified entry changed, then it will return it.
                     */
                    onChange(setIn(projectStatus.iface, [questionId], filteredNewValues));
                }}
                form={form}
            />
        </Container>
    );
};

export default ConfigureImageSegmentation;
