import blobToBuffer from "blob-to-buffer";
import { promisify } from "util";
import * as Minio from "minio";
import urlJoin from "url-join";

// Instantiate the MinIO client
const minioConfig = {
    endPoint: process.env.REACT_APP_MINIO_ENDPOINT,
    bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    useSSL: process.env.REACT_APP_MINIO_SSL == "true",
    port: parseInt(process.env.REACT_APP_MINIO_PORT),
    accessKey: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretKey: process.env.REACT_APP_AWS_SECRET_KEY,
};
const minioClient = new Minio.Client(minioConfig);

const s3UploadMetaData = {
    "Content-Type": "application/octet-stream",
};

export const minioUrl = (
    (process.env.REACT_APP_MINIO_SSL == "true" ? "https://" : "http://") +
        process.env.REACT_APP_MINIO_ENDPOINT + ":" +
        process.env.REACT_APP_MINIO_PORT);

/**
 * Uploads a Blob or Buffer object to the bucket specified by
 * REACT_APP_AWS_BUCKET_NAME in .env
 * @param {Blob | Buffer | File} blobOrBuffer object to upload
 * @param {string} userId
 * @param {string} projectId
 * @param {string} fileName name of the "file" on S3
 * @return Object URL if upload was successful
 */
export async function uploadToS3(blobOrBuffer, userId, projectId, fileName) {

    const objectName = `${userId}/${projectId}/${fileName.replace(/\s|:/g, "_")}`;
    let buffer = blobOrBuffer;
    if (buffer.constructor === Blob || buffer.constructor === File) {
        buffer = await promisify(blobToBuffer)(buffer);
    }

    await new Promise((resolve, reject) => {
        minioClient.putObject(
            minioConfig.bucket,
            objectName,
            buffer,
            s3UploadMetaData,
            (err, result) => {
                if (err) reject(err);
                resolve(result);
            },
        );
    });

    const objectUrl = urlJoin(minioUrl, minioConfig.bucket, objectName);

    return objectUrl;
}
