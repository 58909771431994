import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
    headerButton: {
        color: "#fff",
    },
    saveIcon: {
        marginRight: 4,
    },
    editIcon: {
        marginLeft: 4,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
    },
    textField: {
        marginLeft: 8,
    },
});
