import { makeStyles } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";

export const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        height: "100vh",
        
    },
    headerButton: {
        fontSize: 12,
        backgroundColor: "#fff",
    },
    downloadIcon: {
        marginTop: 2,
        width: 18,
        height: 18,
        marginRight: 4,
        marginLeft: -6,
        color: colors.grey[700],
    },
    languageSelectionWrapper: {
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
    },
});
