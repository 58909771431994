export async function downloadDriveFile(fileId, apiKey, accessToken) {
    const url = `https://www.googleapis.com/drive/v3/files/${fileId}?key=${apiKey}&alt=media`;
    const resp = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!resp.ok || resp.status !== 200) {
        throw new Error(`failed to download files http status is ${resp.status}`);
    }

    const blob = await resp.blob();
    return blob;
}
