// @flow weak

import React from "react";
import ConfigureInterface from "../ConfigureInterface";

const InterfacePage = ({
    dataset,
    projectStatus,
    onChange,
}) => {
    /**
     * destruct then assign to iface variable
     */
    const { interface: iface } = dataset;

    return (
        <div>
            <ConfigureInterface
                projectStatus={projectStatus}
                iface={iface}
                onChange={onChange}
                isNotNested
            />
        </div>
    );
};

export default InterfacePage;
