// @flow

import ImageSearch from "@material-ui/icons/ImageSearch";
import Image from "@material-ui/icons/Image";
import CropFree from "@material-ui/icons/CropFree";

/**
 * We use these templates to
 * provide information for the buttons
 * rendered on choosing the project type.
 *
 * and to render the configuration information
 * after selection.
 */
export const templates = [
    {
        /** this empty mean user did not choose label type yet. */
        name: "Empty",
        Icon: CropFree,
        dataset: {
            interface: {},
            samples: [],
        },
    },
    {
        name: "Classification",
        Icon: Image,
        dataset: {
            interface: {
                type: "image_classification",
                labels: [],
                multiple: null,
            },
            // Q: What is the purpose of these?
            samples: [
                {
                    imageUrl:
                        "https://s3.amazonaws.com/asset.workaround.online/example-jobs/sticky-notes/image1.jpg",
                },
                {
                    imageUrl:
                        "https://s3.amazonaws.com/asset.workaround.online/example-jobs/sticky-notes/image2.jpg",
                },
            ],
        },
    },

    {
        name: "Detection",
        Icon: ImageSearch,
        dataset: {
            interface: {
                type: "image_segmentation",
                labels: [],
                regionTypesAllowed: [
                    "bounding-box",
                ],
                multipleRegions: null,
                multipleRegionLabels: null,
            },
            samples: [
                {
                    imageUrl:
                        "https://s3.amazonaws.com/asset.workaround.online/example-jobs/sticky-notes/image1.jpg",
                },
                {
                    imageUrl:
                        "https://s3.amazonaws.com/asset.workaround.online/example-jobs/sticky-notes/image2.jpg",
                },
            ],
        },
    },
    {
      name: "Segmentation",
      Icon: CropFree,
      dataset: {
        interface: {
          type: "image_pixel_segmentation",
          labels: [],
          description: "",
          // tools in the 'react-image-annotate' library
          tools: ["select", "create-box", "create-polygon", "show-mask"]
        },
        samples: [
          {
            imageUrl:
              "https://s3.amazonaws.com/datasets.workaround.online/faces/010041.jpg",
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/datasets.workaround.online/faces/010026.jpg",
          },
          {
            imageUrl:
              "https://s3.amazonaws.com/datasets.workaround.online/faces/010025.jpg",
          },
        ],
      },
    },
];
