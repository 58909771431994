import React from "react";
import Snackbar from "@material-ui/core/Snackbar";

// what is the difference between theses 2.
import MuiAlert from "@material-ui/lab/Alert";

/**
 * props are:
 * children, - message to be displayed
 * onclose,  - handle clicking on the X button.
 * serverity - type of message alert.
 */
const MyAlert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

export const MySnackBar = (props) => {
    const handleClose = (e, reason) => {
        // for reasons: time out or click away
        props.setOpen(false);
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={props.anchorOrigin}
                open={props.open}
                message="error "
                onClose={handleClose}
                autoHideDuration={props.hideDuration}

            >
                <MyAlert
                    onClose={handleClose}
                    children={props.message}
                    severity={props.severity}
                />
            </Snackbar>
        </div>
    );
};
