import React, { useState, useEffect, useCallback } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import { downloadDriveFile } from "./downloadDriveFile";
import { uploadToS3 } from "../../utils/s3/minio";
import SimpleDialog from "../SimpleDialog";
import { getQueryParams } from "../../utils/getQueryParams";
import { ExplainText } from "./Styled";
import { FaGoogleDrive } from "react-icons/fa";
import { StylesContext } from "@material-ui/styles";
import styles from './uploadGoogle.module.css'
import { getSubscriptionDetails, getStorageUsage } from "../../utils/backend";
import { MySnackBar } from "../helpers/material_ui_helpers/MySnackBar";

// Example for Google OAuth
// https://developers.google.com/drive/api/v3/picker

const credentials = {
    web: {
        client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID,
        api_key: process.env.REACT_APP_GOOGLE_DRIVE_API_KEY,
    },
};

// const scope = "https://www.googleapis.com/auth/drive.file";
const scope = "https://www.googleapis.com/auth/drive.readonly"

export default ({ open, onClose, onAddSamples }) => {
    const { t } = useTranslation();
    const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);
    const [pickerApiLoaded, setPickerApiLoaded] = useState(false);
    const [oauthToken, setOAuthToken] = useState(null); // access token
    const [loading, setLoading] = useState(false);
    const [numberOfImagesUploaded, setNumberOfImagesUploaded] = useState(0);
    const [totalImages, setTotalImages] = useState(0);
    const [userSelectedItemsFromDrive, setUserSelectedItemsFromDrive] = useState(
        [],
    );
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [doCreatePicker, setDoCreatePicker] = useState(false);
    const [onlyImagesAllowed, setOnlyImagesAllowed] = useState(false);

    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [storageLimitExceeded, setStorageLimitExceeded] = useState(false);

    const getSubscription = async () => {
        const queryParams = getQueryParams();
        const subscription = await getSubscriptionDetails(queryParams.uid);
        setSubscriptionDetails(subscription);
    }

    useEffect(() => {
        getSubscription();
    }, []);


    useEffect(() => {
        /** Load google API SDK */
        const scriptElement = document.createElement("script");
        scriptElement.src = "https://apis.google.com/js/api.js";
        scriptElement.async = true;

        scriptElement.onload = () => {
            setGoogleScriptLoaded(true);
        };

        document.body.appendChild(scriptElement);
        return () => {
            scriptElement.remove();
        };
    }, []);

    const handleAuthenticationResponse = useCallback(
        (authenticationResponse) => {
            if (authenticationResponse && !authenticationResponse.error) {
                // this token will enable us to talk to picker API and Drive API
                setOAuthToken(authenticationResponse.access_token);
                setDoCreatePicker(true);
            }
        },
        [],
    );

    const onAuthApiLoad = useCallback(() => {
        if (!oauthToken) {
            /** open pop up for the user to authorize our app */
            window.gapi.auth.authorize(
                {
                    client_id: credentials.web.client_id,
                    scope,
                    immediate: false,
                },
                handleAuthenticationResponse,
            );
        }
    }, [oauthToken]);

    const onPickerApiLoad = useCallback(() => {
        setPickerApiLoaded(true);
        setDoCreatePicker(true);
    }, []);

    const onLoadPicker = useCallback((e) => {
        e.preventDefault();
        if (googleScriptLoaded === true) {
            window.gapi.load("auth", { callback: onAuthApiLoad });
            window.gapi.load("picker", { callback: onPickerApiLoad });
        }
    }, [googleScriptLoaded, onAuthApiLoad, onPickerApiLoad]);

    const googlePickerActionCallback = useCallback(
        /** fire when user selecting files from google drive */
        async (data) => {
            setLoading(true);
            const queryParams = getQueryParams();
            if (data.action === window.google.picker.Action.PICKED) {
                const googleDocs = [];
                const blobUrls = [];
                const fileSizes = [];
                let index = 0;
                let counter = 0;
                setNumberOfImagesUploaded(counter);
                setTotalImages(data.docs.length);

                // Download images and upload them to S3
                const usage = await getStorageUsage(queryParams.uid);
                for (const doc of data.docs) {
                    const { mimeType } = doc;
                    if (mimeType && mimeType.split("/")[0] === "image") {
                        await downloadDriveFile(
                            doc.id,
                            credentials.web.api_key,
                            oauthToken,
                        ).catch((err) => {
                            console.error(`downloadDriveFile() failed with err: ${err}`);
                            throw err;
                        })
                            .then((blob) => {
                                const fileSize = doc.sizeBytes ? doc.sizeBytes : 0;
                                fileSizes.push(fileSize);
                                let totalUploadSize = 0;
                                for (const size of fileSizes) totalUploadSize += size;
                                const updatedUsageGb = (usage.usage_bytes + totalUploadSize)/(2**30)

                                if (subscriptionDetails.storage_limit_gb !== null && updatedUsageGb >= subscriptionDetails.storage_limit_gb)
                                    setStorageLimitExceeded(true);
                                else
                                    return uploadToS3(blob, queryParams.uid, queryParams.pid, doc.name);
                            })
                            .catch((err) => {
                                console.error(`uploadToS3 failed with err: ${err}`);
                                throw err;
                            })
                            .then((blobUrl) => {
                                blobUrls.push(blobUrl);
                                googleDocs.push(doc);
                                setNumberOfImagesUploaded(counter++);
                            });
                    }
                }
                if (data.docs.length > googleDocs.length) setOnlyImagesAllowed(true);
                else if (onlyImagesAllowed) setOnlyImagesAllowed(false);

                setUserSelectedItemsFromDrive(
                    googleDocs.map((googleDriveDocument) => {
                        const obj = {
                            localUrl: blobUrls[index++],
                            url: googleDriveDocument.url,
                            mimeType: googleDriveDocument.mimeType,
                            name: googleDriveDocument.name,
                            id: googleDriveDocument.id,
                        };
                        return obj;
                    }),
                );

                setIsPickerOpen(false);
            } else if (data.action === "cancel") {
                /** this is not the best solution we need to find
             * a way to destory the picker to free memory up */
                onClose();
                setIsPickerOpen(false);
            }
            setLoading(false);
        },
        // not adding onlyImageAllowed state variable was the bug.
        [onClose, oauthToken, credentials, onlyImagesAllowed],
    );

    const createPicker = () => {
        if (pickerApiLoaded && oauthToken) {
            /** we can control file type here */
            const view = new window.google.picker.DocsView(
                window.google.picker.ViewId.DOCS_IMAGES,
                window.google.picker.ViewId.FOLDERS,
            )
                .setIncludeFolders(true)
                .setSelectFolderEnabled(false);

            const picker = new window.google.picker.PickerBuilder()
                // .enableFeature(window.google.picker.Feature.NAV_HIDDEN)
                .enableFeature(window.google.picker.Feature.MINE_ONLY)
                .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
                .setAppId(credentials.web.client_id) // what is this !!!????
                .setOAuthToken(oauthToken)
                .addView(view)
                .addView(window.google.picker.ViewId.DOCS)
                .setCallback(googlePickerActionCallback) // on selecting files
                .build();
            picker.setVisible(true);
            setIsPickerOpen(true);
        }
    };

    useEffect(() => {
        if (doCreatePicker && !isPickerOpen) {
            setDoCreatePicker(false);
            createPicker();
        }
    }, [doCreatePicker, oauthToken, pickerApiLoaded]);

    const onAddSamplesClicked = () => {
        const samples = [];
        /** you will need to change here also ***************** */
        for (const item of userSelectedItemsFromDrive) {
            samples.push({ imageUrl: item.localUrl });
        }
        onAddSamples(samples);
    };

    /** dialog UI */
    return (
        <div>
            {subscriptionDetails && (<MySnackBar
                open={storageLimitExceeded}
                setOpen={setStorageLimitExceeded}
                message={`Your project storage has exceeded ${subscriptionDetails.storage_limit_gb}GB, the limit for your current plan`}
                severity="error"
                hideDuration={7000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            />)}

            <SimpleDialog open={loading} title="Uploading">
                <div>
                    <center>
                        <CircularProgress />
                        <h2>
                            {numberOfImagesUploaded}
                            {" "}
                            Images out of
                            {" "}
                            {totalImages}
                            {" "}
                            have been
                            uploaded
                        </h2>
                    </center>
                </div>
            </SimpleDialog>

            <SimpleDialog
                open={open && !isPickerOpen}
                onClose={onClose}
                title="Import from Google Drive"
                actions={[
                    userSelectedItemsFromDrive
                    && userSelectedItemsFromDrive.length > 0 && {
                        text: `Add ${userSelectedItemsFromDrive.length} Samples`,
                        onClick: onAddSamplesClicked,
                    },
                ].filter(Boolean)}
            >

                <ExplainText>
                    {t("import-from-google-drive-explanation-text")}
                    .
                    {" "}
                    <b>
                        {t("import-from-google-drive-explanation-bold-text")}
                        !
                    </b>
                    {onlyImagesAllowed && (
                        <p style={{ color: "red" }}>
                            only Images Allowed, non Image Files are removed
                        </p>
                    )}
                </ExplainText>
                <button className={styles.uploadGoogleDrive} variant="outlined" onClick={onLoadPicker}>
                    Open Google Drive Picker
                    <FaGoogleDrive className={styles.driveIcon} />
                </button>
            </SimpleDialog>
        </div>
    );
};
