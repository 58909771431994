// @flow

import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import useEventCallback from "use-event-callback";
import Checkbox from "@material-ui/core/Checkbox";
import WorkspaceContainer from "../WorkspaceContainer";
import AnnotoriousComponent from "./Annotorious";
import { emptyLabel } from "./util";
import { MySnackBar } from "../helpers/material_ui_helpers/MySnackBar";
import { WorkspaceToolContext } from "../../providers/workspaceToolContext";

import styles from './annotations.module.css'

import {
    Container, CheckButton
} from "./Styled";

const letters = "abcdefghijklmnopqrstuvwxyz1234567890".split("");

const [emptyObj, emptyArr] = [{}, []];

const ImageAnnotorious = ({
    sampleIndex: globalSampleIndex,
    interface: iface,
    samples = emptyArr /** samples is empty, it is not passed to us from any previous component */,
    containerProps = emptyObj,
    onSaveTaskOutputItem,
    numberOfSamples,
    setBackCallback,
}) => {
    // TODO remove legacy "availableLabels" support
    if (iface.availableLabels && !iface.labels) {
        iface.labels = iface.availableLabels;
    }

    if (!iface.labels) { throw new Error("No labels defined. Add some labels in Setup to continue."); }
    const [sampleIndex, changeSampleIndex] = useState(0);
    const [enlargedLabel, changeEnlargedLabel] = useState(null);
    const [value, setValue] = useState(emptyLabel);
    const [annotorious, setAnnotorious] = useState();
    const [tool, setTool] = useState("rect");
    const [annotations, setAnnotations] = useState();
    const [unlabeledAnnotationsError, setUnlabeledAnnotationsError] = useState(false);

    const labels = useMemo(
        () => iface.labels.map((l) => (typeof l === "string" ? { id: l, description: l } : l)),
        [iface.labels],
    );

    const { workspaceTool, setWorkspaceTool } = useContext(WorkspaceToolContext);

    function createAnnot(tag) {
        return [{ type: "TextualBody", value: tag.id, purpose: "tagging" }];
    }

    const onDone = useEventCallback(() => {
        if (annotorious.getAnnotations()) {
            // TODO: await this?
            onSaveTaskOutputItem(sampleIndex, annotorious.getAnnotations());
        }
        if (containerProps.onExit) containerProps.onExit();
        setWorkspaceTool(null);
    });

    const checkUnlabeledAnnotations = (annotations) => {
        for (const annotation of annotations) {
            if (!annotation.body[0].hasOwnProperty("value") || annotation.body[0].value === null) {
                return true;
            }
        }
        return false;
    };

    const onNext = useEventCallback(async () => {
        await annotorious.saveSelected();
        const annotations = annotorious.getAnnotations();
        if (checkUnlabeledAnnotations(annotations)) {
            setUnlabeledAnnotationsError(true);
            return;
        }

        onSaveTaskOutputItem(sampleIndex, annotations);
        annotorious.setAnnotations([]);

        if (sampleIndex !== samples.length - 1) {
            changeSampleIndex(sampleIndex + 1);
        } else if (containerProps.onExit) containerProps.onExit("go-to-next");
    });

    const saveAndPrev = async () => {
        await annotorious.saveSelected();
        const annotations = annotorious.getAnnotations();
        if (checkUnlabeledAnnotations(annotations)) {
            setUnlabeledAnnotationsError(true);
            return false;
        }

        // TODO: await this?
        onSaveTaskOutputItem(sampleIndex, annotations);

        if (sampleIndex > 0) {
            changeSampleIndex(sampleIndex - 1);
            annotorious.setAnnotations([]);
        } else if (containerProps.onExit) containerProps.onExit("go-to-previous");
        return true;
    };

    const onPrev = useEventCallback(saveAndPrev);

    useEffect(() => {
        setBackCallback({ cb: onPrev });
    }, []);

    useEffect(() => {
        if (workspaceTool) setTool(workspaceTool);
    })

    useEffect(() => {
        const timeout = setTimeout(() => {
            changeEnlargedLabel(null);
        }, 100);
        return () => clearTimeout(timeout);
    }, [enlargedLabel]);

    const onClickLabel = useEventCallback(async (label) => {
        // Update selected annotation label
        const selected = annotorious.getSelected();
        const updatedAnno = { ...selected, body: createAnnot(label) };
        await annotorious.updateSelected(updatedAnno, true);
        annotorious.selectAnnotation(updatedAnno);
        changeEnlargedLabel(label);
        setValue(label);
    });

    const leftsideBarIconsSeg = [
        {
            name: "polygon",
            onClick: () => {
                setTool("polygon");
                setWorkspaceTool("polygon");
            },
            selected: tool === "polygon",
        },
    ];

    const leftsideBarIcons = [
        {
            name: "rect",
            onClick: () => {
                setTool("rect");
                setWorkspaceTool("rect");
            },
            selected: tool === "rect",
        },
    ];

    const removeSelected = () => {
        const selection = annotorious.getSelected();
        if (selection) annotorious.removeAnnotation(selection);
    };

    useEffect(() => {
    const annotations = samples[sampleIndex].annotation;

        if (annotations) {
            setAnnotations(annotations);
        }
    }, [sampleIndex, globalSampleIndex, samples]);

    const [hotkeyMap, labelKeyMap] = useMemo(() => {
        const hotkeyMap = {
            arrowright: onNext,
            arrowleft: onPrev,
        };
        const labelKeyMap = {};
        for (const label of labels) {
            const nextAvailableLetter = label.id
                .split("")
                .filter((l) => letters.includes(l))
                .find((l) => !hotkeyMap[l.toLowerCase()]);
            if (nextAvailableLetter) {
                hotkeyMap[nextAvailableLetter] = () => onClickLabel(label);
                labelKeyMap[label.id] = nextAvailableLetter;
            }
        }
        return [hotkeyMap, labelKeyMap];
    }, [labels, onClickLabel, onDone, onNext, onPrev]);

    useEffect(() => {
        const onKeyDown = (e) => {
            const key = e.key.toLowerCase();
            if (hotkeyMap[key]) {
                hotkeyMap[key]();
            }
        };
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        };
    }, [hotkeyMap]);

    const annoComponent = useRef(null);

    const onZoomIn = () => {
        if (annoComponent.current) annoComponent.current.zoomIn();
    };

    const onZoomOut = () => {
        if (annoComponent.current) annoComponent.current.zoomOut();
    };

    return (
        <>
            <MySnackBar
                open={unlabeledAnnotationsError}
                setOpen={setUnlabeledAnnotationsError}
                message="Please make sure all annotations are labeled"
                severity="error"
                hideDuration={3000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            />

            <section className={styles.classificationWrapper}>

                <WorkspaceContainer

                    onNext={() => {
                        /** this is made in this way as WorkspaceContainer
                        pass unExpected parameters to onNext call back function
                        */
                        onNext();
                    }}
                    onPrev={onPrev}
                    onZoomIn={onZoomIn}
                    onZoomOut={onZoomOut}
                    onClickHeaderItem={onDone}
                    numberOfSamples={numberOfSamples}
                    currentSampleIndex={sampleIndex}
                    globalSampleIndex={globalSampleIndex}
                    leftsideBarIcons={iface.type === "image_segmentation" ? leftsideBarIcons : leftsideBarIconsSeg}
                >
                    <Container
                        style={{
                            height: containerProps.height || "100%",
                        }}
                        className="annotorious-container"
                    >
                        <div className="annotorious-container">
                            <AnnotoriousComponent
                                imageUrl={samples[sampleIndex].imageUrl}
                                annotorious={annotorious}
                                setAnnotorious={setAnnotorious}
                                value={value}
                                annotations={annotations}
                                tool={tool}
                                setValue={setValue}
                                labels={labels}
                                ref={annoComponent}
                            />
                        </div>
                        <section className={styles.btnsContainer}>
                            {labels.map((label) => (
                                <>
                                    <CheckButton
                                        key={label.id}
                                        onClick={() => onClickLabel(label)}
                                        style={{
                                            transform: enlargedLabel === label ? "scale(1.1,1.1)" : undefined,
                                        }}
                                        className={styles.btnPrimary}
                                    >
                                        <Checkbox style={{ color: "#fff" }} checked={value === label} />
                                        {label.id}
                                        {labelKeyMap[label.id] ? ` (${labelKeyMap[label.id]})` : ""}
                                    </CheckButton>
                                    <br />
                                </>

                            ))}
                            <div className={styles.btnRemove}>
                                <button className={styles.btnSecondary} onClick={removeSelected}>Remove</button>
                            </div>
                        </section>
                    </Container>
                </WorkspaceContainer>
            </section>
        </>
    );
};

export default ImageAnnotorious;
