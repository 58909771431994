import React, { Suspense } from "react";
import Theme from "./components/Theme";
import LocalStorageApp from "./components/LocalStorageApp";
import { ToastProvider } from "./components/Toasts";
import { AppConfigProvider } from "./components/AppConfig";
import { HotkeyStorageProvider } from "./components/HotkeyStorage";
import "./App.css";

import Loading from "./components/Loading";

// Importing Internalization file
import "./i18n";

export const App = () => (
    <Suspense fallback={Loading}>
        <Theme>
            <AppConfigProvider>
                <ToastProvider>
                    <HotkeyStorageProvider>
                        <LocalStorageApp />
                    </HotkeyStorageProvider>
                </ToastProvider>
            </AppConfigProvider>
        </Theme>
    </Suspense>
);

export default App;
