import { createContext } from "react";


export const HeaderContext = createContext({
    recentItems: [],
    changeRecentItems: () => null,
    onClickTemplate: () => null,
    onClickHome: () => null,
    onOpenFile: () => null,
    onOpenRecentItem: () => null,
    inSession: false,
    onJoinSession: () => null,
    onCreateSession: () => null,
    onLeaveSession: () => null,
    sessionBoxOpen: false,
    changeSessionBoxOpen: () => null,
    fileOpen: false,
    onDownload: () => null,
    authConfig: null,
    onUserChange: () => null,
    user: null,
    logoutUser: () => null,
});
