import { styled } from "@material-ui/core/styles";
import MuiButton from "@material-ui/core/Button";
import * as colors from "@material-ui/core/colors";

export const ButtonBase = styled(MuiButton)({
    width: 100,
    height: 50,
    display: "inline-flex",
    flexDirection: "column",
    backgroundColor: "#00005D",
    color: "#ffffff",
    "&.disabled": {
        backgroundColor: colors.grey[200],
    },
    margin: 8,
    "& .icon": {
        width: 30,
        height: 30,
        color: "#ffffff",
        "&.disabled": {
            color: colors.grey[400],
        },
    },
});
