export const valid_file_extension = ['jpg', 'jpeg', 'bmp','png']

export function check_file_extension(filePath)
{
    let pathParts = filePath.split('.');
    let ext = pathParts[pathParts.length-1].toLowerCase();
    if(valid_file_extension.includes(ext))
        return true;
    return false;    
}

export function getValidImageExt()
{
    let res = valid_file_extension.map(ext=> "image/"+ext);
    res = res.join(',');
    //console.log("valid ext is :" , res);
    return res;
}
