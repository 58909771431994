import { styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const BigTaskButton = styled(Button)({
    padding: 10,
    width: "30%",
    height: 300,
    boxShadow: "0px 3px 5px rgba(0,0,0,0.3)",
    margin: 12,
    backgroundColor: "#fff",
    color: "#00005D",
    "& .bigIcon": {
        marginTop: 16,
        width: 64,
        height: 64,
        color: "#fff",
    },
    "&:hover": {
        backgroundColor: "#ccc",
    },
});
