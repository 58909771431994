// @flow
import React, { useState, useCallback } from "react";
import download from "in-browser-download";
import { setIn } from "seamless-immutable";
import useEventCallback from "use-event-callback";
import { HeaderContext } from "../HeaderContext";
import StartingPage from "../StartingPage";
import DatasetEditor from "../DatasetEditor";
import ErrorToasts from "../ErrorToasts";
import useErrors from "../../utils/use-errors";
import useFileHandler from "../../utils/file-handlers";
import toUDTCSV from "../../utils/to-udt-csv";
import AppErrorBoundary from "../AppErrorBoundary";
import usePreventNavigation from "../../utils/use-prevent-navigation";
import { FileContext } from "../FileContext";

const randomId = () => Math.random().toString().split(".")[1];

const LocalStorageApp = () => {
    const {
        file, // assigned to the dataset object.
        setFile,
        openFile,
        openUrl,
        makeSession,
        recentItems,
        changeRecentItems,
    } = useFileHandler(); // what does this do !!!???
    usePreventNavigation(Boolean(file));
    const [errors] = useErrors();

    const server = "http://localhost";
    const [selectedBrush, setSelectedBrush] = useState("complete");
    const onCreateTemplate = useEventCallback((template) => {
        setFile({
            fileName: window.location.href.split("id=")[1],
            content: template.dataset,
            id: randomId(),
            mode: "local-storage",
        });
    });

    const openRecentItem = useEventCallback((item) => setFile(item));
    const onClickHome = useEventCallback(() => setFile(null));
    const onDownload = useEventCallback((format) => {
        if (!file) return;
        const outputName = `${file.sessionId || file.fileName}.udt.${format}`;
        if (format === "json") {
            download(JSON.stringify(file.content), outputName);
        } else if (format === "csv") {
            download(toUDTCSV(file.content), outputName);
        } else if (format === "pre-trained") {
            window.open(`${server}:3000/TrainConfig`); // ?"+"id="+window.location.href.split("id=")[1]+"&mode=pretrained" +"&data="+JSON.stringify(file.content)  , "_blank")
        } else {
            const jsonDown = file.content;
            let validToDownload = true;
            if (
                Object.keys(jsonDown.interface).length === 0
        && jsonDown.interface.constructor === Object
            ) {
                alert("You cannot train without specifying an interface");
                validToDownload = false;
                return;
            }

            if (
                jsonDown.interface.type === "image_classification"
        && jsonDown.samples.length === 0
            ) {
                alert("Cannot start training without loading samples");
                validToDownload = false;
                return;
            }

            jsonDown.interface.labels.forEach((annotation) => {
                const count = jsonDown.samples.filter((e) => e.annotation === annotation.id).length;
                // if (count < 1) {
                //   alert(
                //     `Every class label in image classfication should be at least 50 samples. Class ${annotation.id} has less than 50 images.`
                //   )
                //   validToDownload = false
                //   return
                // }
            });

            if (validToDownload) window.open(`${server}:3000/process`); // ?"+"id="+window.location.href.split("id=")[1]+"&mode=pretrained" +"&data="+JSON.stringify(file.content)  , "_blank")
        }
    });

    const inSession = file && file.mode === "server";
    const [sessionBoxOpen, changeSessionBoxOpen] = useState(false);

    const onJoinSession = useCallback(
        async (sessionName) => {
            await openUrl(sessionName);
        },
        [openUrl],
    );

    const onLeaveSession = useEventCallback(() => setFile({
        ...file,
        mode: "local-storage",
        fileName: file.fileName || `copy_of_${file.id}`,
    }));

    const onChangeDataset = useEventCallback((newOHA) => {
        setFile(setIn(file, ["content"], newOHA));
    });


    return (
        <>
            <FileContext.Provider value={{ file, setFile }}>
                <HeaderContext.Provider
                    value={{
                        title: file
                            ? file.mode === "local-storage"
                                ? file.fileName
                                : file.url
                            : "unnamed",
                        recentItems,
                        changeRecentItems,
                        onClickTemplate: onCreateTemplate,
                        onClickHome,
                        onOpenFile: openFile,
                        onOpenRecentItem: openRecentItem,
                        inSession,
                        sessionBoxOpen,
                        changeSessionBoxOpen,
                        onJoinSession,
                        onLeaveSession,
                        onCreateSession: makeSession,
                        fileOpen: Boolean(file),
                        onDownload,
                        onChangeSelectedBrush: setSelectedBrush,
                        selectedBrush,
                        isWelcomePage: !file,
                    }}
                >
                    {!file ? (
                        <StartingPage
                            onOpenTemplate={onCreateTemplate}
                        />
                    ) : (
                        <AppErrorBoundary>
                            {/** this is our entry point for Dataset Editor */}
                            <DatasetEditor
                                file={file}
                                key={file.id}
                                {...file}
                                selectedBrush={selectedBrush}
                                inSession={inSession}
                                dataset={file.content}
                                onChangeDataset={onChangeDataset}
                                onChangeFile={setFile}
                                authConfig
                                recentItems={recentItems}
                                editDataSet={(newDataSet) => {
                                    setFile({
                                        fileName: file.fileName,
                                        content: newDataSet,
                                        id: file.id,
                                        mode: file.mode,
                                    });
                                }}
                            />
                        </AppErrorBoundary>
                    )}
                </HeaderContext.Provider>
            </FileContext.Provider>
            <ErrorToasts errors={errors} />
        </>
    );
};

export default LocalStorageApp;
