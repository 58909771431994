import Button from "@material-ui/core/Button";
import { styled } from "@material-ui/core/styles";

export const Container = styled("div")({
    maxWidth: "100vw",
    display: "flex",
    flexDirection: "row",
    height: "100%",
});

export const ButtonsContainer = styled("div")({
    paddingLeft: 50,
    paddingRight: 50,
    marginRight: 50,
    marginTop: 8,
    textAlign: "center",
    flexShrink: 0,
    width: "15%",
    height: "500px",
    overflowY: "scroll",
    overflowX: "hidden",

});

export const ControlButton = styled(Button)({
    margin: 8,
    color: "#fff",
    fontSize: 18,
    paddingRight: 16,
    transition: "transform 50ms ease",
    width: "100%",
});

export const CheckButton = styled(ControlButton)({
    width: "10rem",
    height: "4rem",
    border: "1px solid #312ECB",
    backgroundColor: "#312ECB",
    borderRadius: "0.7rem",
    padding: "0.8rem 1rem",
    fontSize: "18px",
    fontWeight: 500,
    color: "#fff",
    cursor: "pointer",
    transition: "all 0.3s ease-in-out",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "14px",
    margin: "4px 0 6px 18px",
    "&:hover": {
        backgroundColor: "#120f95",
    },
});

export const RemoveButton = styled(ControlButton)({
    backgroundColor: "#5D0000",
    "&:hover": {
        backgroundColor: "#6A0000",
    },
    height: "3.4rem",
});

export const ImageContainer = styled("p")({
    flexGrow: 1,
    display: "block",
    width: "100%",
});
