import React, {
    memo,
    useCallback,
    useReducer,
    useEffect,
    useState,
} from "react";
import useEventCallback from "use-event-callback";
import { useLocalStorage } from "react-use";
import { styled, makeStyles } from "@material-ui/core/styles";
import range from "lodash/range";
import * as colors from "@material-ui/core/colors";
import classNames from "classnames";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import getBrushColorPalette from "../../utils/get-brush-color-palette";
import { deleteSample, unLabelSample } from "../../utils/datasetOps/datasetOps";
import styles from "./sample-grid.module.css";

const Container = styled("div")({
    flexWrap: "wrap",
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    gap: 4,
});
const EmptyState = styled("div")({
    fontSize: 24,
    color: colors.grey[500],
    padding: 30,
    textAlign: "center",
});
const SampleDiv = styled("div")(({ color }) => ({
    border: "1px solid rgb(49 46 203 / 23%)",
    color: "#312ECB",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    gap: 10,
    fontVariantNumeric: "tabular-nums",
    margin: 4,
    padding: "25px 15px",
    borderRadius: 10,
    backgroundColor: color[50],
    display: "inline-flex",
    fontSize: 32,
    fontWeight: 500,
    textAlign: "center",
    justifyContent: "center",
    minWidth: "3em",
    borderRadius: 12,
    cursor: "pointer",
    userSelect: "none",
    boxShadow: "0 1.2rem 3.2rem rgba(0, 0, 0, 0.06)",
    transition:
        "box-shadow 200ms ease, transform 200ms ease, background-color 200ms ease",
    "&:hover": {
        backgroundColor: "#DDDDFF",
    },
    // color: color.isFaded ? "#000" : "#fff",
    "&.selected": {
        backgroundColor: "#DDDDFF",
        boxShadow: "0px 0px 2px 1px #00005D",
        transform: "scale(1.1,1.1)",
    },
}));

// const gallery = [
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//         title: "Burger",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//         title: "Camera",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//         title: "Coffee",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//         title: "Hats",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//         title: "Honey",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//         title: "Burger",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//         title: "Camera",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//         title: "Coffee",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//         title: "Hats",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//         title: "Honey",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
//         title: "Breakfast",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
//         title: "Burger",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
//         title: "Camera",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
//         title: "Coffee",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
//         title: "Hats",
//     },
//     {
//         img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
//         title: "Honey",
//     },
// ];

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
}));

const Sample = memo(
    ({
        setChange,
        dataset,
        onChangeDataset,
        projectStatus,
        saveProjectStatus,
        index,
        onClick,
        completed,
        selected,
        onMouseDown,
        onMouseUp,
        onMouseEnter,
        brush,
        image,
    }) => {
        console.log("projectStatus", projectStatus);
        console.log("data set from sample grid", dataset);

        const handleDeleteSample = async (e, index) => {
            const newDataSet = deleteSample(index, dataset);
            onChangeDataset(newDataSet);
            const status = { ...projectStatus, samples: newDataSet.samples };
            await saveProjectStatus(status);
        };

        const handleUnLabel = async (e, index) => {
            const newDataSet = unLabelSample(index, dataset);
            onChangeDataset(newDataSet);
            const status = { ...projectStatus, samples: newDataSet.samples };
            await saveProjectStatus(status);
            setChange((ch) => !ch);
        };

        return (
            // little square image in the data set grid.
            <>
                <SampleDiv
                    className={classNames({ completed, selected })}
                    // onClick={() => onClick(index)}
                    onMouseDown={() => onMouseDown(index)}
                    onMouseUp={() => onMouseUp(index)}
                    onMouseEnter={() => onMouseEnter(index)}
                    color={getBrushColorPalette(brush)}
                >
                    {index + 1}
                    <img
                        onClick={() => onClick(index)}
                        src={image}
                        width={350}
                        height={300}
                        alt=""
                    />
                    <div className={styles.btnActions}>
                        <button
                            className={styles.deleteBtn}
                            type="button"
                            onClick={(e) => handleDeleteSample(e, index)}
                        >
                            Delete
                        </button>

                        <button
                            className={styles.removeLabel}
                            type="button"
                            onClick={(e) => handleUnLabel(e, index)}
                            disabled={!completed}
                        >
                            Remove Label
                        </button>
                        {completed && (
                            <span className={styles.labeledSpan}>Labeled</span>
                        )}
                    </div>
                </SampleDiv>
            </>
        );
    },
    (p, n) =>
        p.index === n.index &&
        p.completed === n.completed &&
        p.selected === n.selected &&
        p.brush === n.brush
);

const SampleGrid = ({
    dataset,
    onChangeDataset,
    projectStatus,
    saveProjectStatus,
    completed = [],
    samples,
    onClick,
}) => {
    const [samplesPerPage, changeSamplesPerPage] = useLocalStorage(
        "samplesPerPage",
        100
    );

    const [sampleOffset, changeSampleOffset] = useLocalStorage(
        "sampleOffset",
        0
    );
    useEffect(() => {
        if (sampleOffset > samples.length) {
            changeSampleOffset(0);
        }
    }, [changeSampleOffset, sampleOffset, samples.length]);

    const [selectRange, changeSelectRange] = useReducer((state, newValue) => {
        if (newValue === null) return null;
        if (typeof newValue === "number") {
            if (!state) return null;
            return [
                Math.min(newValue, state[0]),
                Math.max(newValue + 1, state[1]),
            ];
        }
        return newValue;
    }, null);

    const startSelectRange = useCallback(
        (index) => changeSelectRange([index, index + 1]),
        [changeSelectRange]
    );
    const moveSelectRange = useCallback(
        (index) => changeSelectRange(index),
        [changeSelectRange]
    );
    const endSelectRange = useCallback(() => {}, []);
    const checkAndNullifySelectRange = useCallback(
        (e) => {
            if (e.buttons !== 1) {
                changeSelectRange(null);
            }
        },
        [changeSelectRange]
    );
    const onClickMemo = useEventCallback(onClick);
    const classes = useStyles();

    const [change, setChange] = useState(false);
    return (
        <Container
            onMouseUp={checkAndNullifySelectRange}
            onMouseEnter={checkAndNullifySelectRange}
        >
            {samples.length === 0 ? (
                <EmptyState>
                    No samples, try using &quot;Import Toy Dataset&quot; in
                    Samples `{">"}` Import
                </EmptyState>
            ) : (
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="center" spacing={2}>
                            {range(
                                sampleOffset,
                                Math.min(
                                    samples.length,
                                    sampleOffset + samplesPerPage
                                )
                            ).map((i) => (
                                <Grid
                                    key={`${i.toString()}_${samples.length.toString()}_${change.toString()}`}
                                    item
                                >
                                    {" "}
                                    {/** make the key unique */}
                                    <Sample
                                        setChange={setChange}
                                        dataset={dataset}
                                        onChangeDataset={onChangeDataset}
                                        projectStatus={projectStatus}
                                        saveProjectStatus={saveProjectStatus}
                                        onClick={onClickMemo}
                                        key={`${i.toString()}_${samples.length.toString()}_${change.toString()}`}
                                        index={i}
                                        image={samples[i].imageUrl}
                                        completed={completed[i]}
                                        brush={
                                            completed[i]
                                                ? samples[i].brush || "complete"
                                                : "incomplete"
                                        }
                                        selected={
                                            selectRange &&
                                            i >= selectRange[0] &&
                                            i < selectRange[1]
                                        }
                                        onMouseDown={startSelectRange}
                                        onMouseEnter={moveSelectRange}
                                        onMouseUp={endSelectRange}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <TablePagination
                rowsPerPageOptions={[100, 250, 500, 1000, 2000, 10000]}
                component="div"
                count={samples.length}
                rowsPerPage={samplesPerPage}
                page={Math.floor(sampleOffset / samplesPerPage)}
                labelRowsPerPage="Samples per Page:"
                onChangePage={(e, newPage) =>
                    changeSampleOffset(newPage * samplesPerPage)
                }
                onChangeRowsPerPage={(e) =>
                    changeSamplesPerPage(+e.target.value)
                }
            />
        </Container>
    );
};

export default SampleGrid;
