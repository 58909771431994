// @flow

import "../../i18n";
import React, { useMemo } from "react";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import ImageClassification from "../ImageClassification";
import EmptySampleContainer from "../EmptySampleContainer";
import BadOHA from "../BadOHA";
import ImageAnnotorious from "../ImageAnnotorious";

export const UniversalDataViewer = ({
    dataset,
    numberOfSamples,
    onExit,
    hideHeader,
    hideDescription,
    datasetName,
    requireCompleteToPressNext,
    onSaveTaskOutputItem,
    height,
    onClickSetup,
    setBackCallback,
}) => {
    // TODO type check w/ superstruct against dataset
    const { t } = useTranslation();
    const containerProps = useMemo(
        () => ({
            hideHeader,
            hideDescription,
            datasetName,
            requireCompleteToPressNext,
            onExit,
            height,
        }),
        [
            hideHeader,
            hideDescription,
            requireCompleteToPressNext,
            datasetName,
            height,
            onExit,
        ],
    );

    if (!dataset || !dataset.interface.type) {
        return (
            <BadOHA
                title="Set up your project to begin labeling"
                description={(
                    <p>
                        {t("universal-data-viewer-interface-warning")}
                        <br />
                        <br />
                        <Button color="primary" variant="contained" onClick={onClickSetup}>
                            {t("setup-project")}
                        </Button>
                    </p>
                )}
            />
        );
    }

    /**
   * samples always represent a single instance
   * from the data set.
   */
    if (!dataset.samples || dataset.samples.length === 0) {
        return <EmptySampleContainer />;
    }

    switch (dataset.interface.type) {
    case "image_segmentation":
    case "image_pixel_segmentation":
        return (
            <ImageAnnotorious
                containerProps={containerProps}
                interface={dataset.interface}
                samples={dataset.samples}
                sampleIndex={dataset.sampleIndex}
                numberOfSamples={numberOfSamples}
                onExit={onExit}
                onSaveTaskOutputItem={onSaveTaskOutputItem}
                setBackCallback={setBackCallback}
            />
        );
    case "image_classification":
        return (
            <ImageClassification
                containerProps={containerProps}
                interface={dataset.interface}
                samples={dataset.samples}
                sampleIndex={dataset.sampleIndex}
                numberOfSamples={numberOfSamples}
                onExit={onExit}
                onSaveTaskOutputItem={onSaveTaskOutputItem}
            />
        );
    default:
        return `"${dataset.interface.type}" not supported`;
    }
};

export default UniversalDataViewer;
