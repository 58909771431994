import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { getIn, setIn } from "seamless-immutable";
import duration from "duration";
import { styled } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Button from "@material-ui/core/Button";
import download from "in-browser-download";
import urlJoin from "url-join";

import SampleGrid from "../SampleGrid";
import Stats from "../Stats";
import UniversalDataViewer from "../UniversalDataViewer";

import { MySnackBar } from "../helpers/material_ui_helpers/MySnackBar";
import { preTrainingConditions } from "../../utils/preTrainingConditions/preTrainingConditions";
import { ErrorDialog } from "../helpers/material_ui_helpers/ErrorDialog";
import { saveStatistics } from "../../firebase/realtimeDB";
import { getStatistics } from "../../utils/datasetOps/datasetOps";
import { getUserDataUrl } from "../../utils/userData";
import { getQueryParams } from "../../utils/getQueryParams";
import styles from "./label-view.module.css";
import { consumeImagePoints } from "../../utils/backend";
import { UserContext } from "../UserContext";
import { LoadingContext } from "../LoadingContext";
import { downloadDatasetAsCoco, prepareDataset } from "../../utils/backend";
import { unLabelSample } from "../../utils/datasetOps/datasetOps";

const OverviewContainer = styled("div")({
    padding: 16,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
});

const LabelView = ({
    dataset,
    onChangeDataset,
    projectStatus,
    setProjectStatus,
    saveProjectStatus,
    singleSampleDataset,
    onChangeSingleSampleDataset,
    selectedBrush = "complete",
    onClickSetup,
    onChangeSampleTimeToComplete,
    sampleTimeToComplete,
    setBackCallback,
    // ENABLE IMAGE POINTS
    // setInsufficientImagePointsError
}) => {
    const [currentTab, setTab] = useState("label");
    const [invalidProject, setInvalidProject] = useState(false);
    const [status, setStatus] = useState(false);
    const [timePerSample, setTimePerSample] = useState(0);
    const [estimatedRemaining, setEstimatedRemaining] = useState(0);
    const user = useContext(UserContext);
    const loadingContext = useContext(LoadingContext);

    // Snackbar states
    const [projectExported, setProjectExported] = useState(false);
    const [unlabeledImagesError, setUnlabeledImagesError] = useState(false);

    let percentComplete = 0;
    if (dataset.samples && dataset.samples.length > 0) {
        percentComplete =
            dataset.samples
                .map((s) => s.annotation !== undefined && s.annotation !== null)
                .filter(Boolean).length / dataset.samples.length;
    }

    useEffect(() => {
        onChangeDataset(
            setIn(
                setIn(dataset, ["samples"], projectStatus.samples),
                ["interface"],
                projectStatus.iface
            ) // concatenate data sets.
        );
    }, []);

    useEffect(() => {
        if (window.location.href.split("type=")[1] === "labeling") {
            setStatus(true);
        }
    }, []);

    const countLabeledSamples = () => {
        return dataset.samples
            .map(
                (s) => s.annotation !== undefined && s.annotation !== null,
            )
            .filter(Boolean).length;
    };
                                
    const countUnlabeledSamples = () => {
        return dataset.samples.length
        - dataset.samples
            .map(
                (s) => s.annotation !== undefined && s.annotation !== null,
            )
            .filter(Boolean).length;
    };

    const exportFile = () => {
        if (dataset.interface.type === "image_classification" && countUnlabeledSamples() !== 0) {
            setUnlabeledImagesError(true);
            console.error("Some images are unlabeled.");
            return;
        }

        const { uid, pid, type } = getQueryParams();
        const outputName = `${pid}.udt.json`;

        prepareDataset(pid, dataset)
            .then((responseJson) => {
                download(JSON.stringify(dataset.samples), outputName);
                setProjectExported(true);
            })
            .catch((error) => {
                console.error("Error in exporting project data ", error);
            });

        // save statistics data into the firebase DB.
        const { userId, projId } = getUserDataUrl();
        try {
            saveStatistics(
                userId,
                projId,
                getStatistics(dataset, percentComplete, sampleTimeToComplete)
            );
        } catch (err) {
            console.error("Failed to save statistics data", err);
        }
    };

    const exportToCoco = async () => {
        const { uid, pid, type } = getQueryParams();
        downloadDatasetAsCoco(pid, dataset);
    };

    // Project Type e.g., labeling
    const { type: projectType } = getQueryParams();

    const startTraining = async () => {
        if (dataset.interface.type === "image_classification" && countUnlabeledSamples() !== 0) {
            setUnlabeledImagesError(true);
            console.error("Some images are unlabeled.");
            return;
        }

        const { uid, pid, type } = getQueryParams();
        try {
            if (projectType !== "auto_annotation")
                await prepareDataset(pid, dataset);
            const { userId, projId } = getUserDataUrl();
            try {
                saveStatistics(
                    userId,
                    projId,
                    getStatistics(dataset, percentComplete, sampleTimeToComplete),
                );
            } catch (err) {
                console.error("Failed to save statistics data", err);
            }

            if (dataset.interface.type === "image_classification") {
                window.location.replace(
                    `${process.env.REACT_APP_IAM}TrainConfig?id=${pid}`,
                );
            } else {
                window.location.replace(
                    `${process.env.REACT_APP_IAM}TrainConfig?id=${pid}`,
                );
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const time = duration(
            new Date(Date.now() - sampleTimeToComplete)
        ).toString(1, 1);
        const remaining = duration(
            new Date(
                Date.now() -
                    sampleTimeToComplete *
                        (1 - percentComplete) *
                        (dataset.samples || []).length
            )
        ).toString(1, 2);

        setTimePerSample(time);
        setEstimatedRemaining(remaining);
        const status = {
            ...projectStatus,
            timePerSample: time,
            estimatedRemaining: remaining,
        };
        setProjectStatus(status);
    }, [sampleTimeToComplete]);

    useEffect(() => {
        if (projectStatus.hasOwnProperty("timePerSample"))
            setTimePerSample(projectStatus.timePerSample);
        if (projectStatus.hasOwnProperty("estimatedRemaining"))
            setEstimatedRemaining(projectStatus.estimatedRemaining);
    }, []);

    const onExit = (nextAction = "nothing") => {
        if (singleSampleDataset.startTime) {
            onChangeSampleTimeToComplete(
                Date.now() - singleSampleDataset.startTime
            );
        }
        const { sampleIndex } = singleSampleDataset;
        switch (nextAction) {
            case "go-to-next":
                if (sampleIndex !== dataset.samples.length - 1) {
                    onChangeSingleSampleDataset({
                        ...dataset,
                        samples: [dataset.samples[sampleIndex + 1]],
                        sampleIndex: sampleIndex + 1,
                        startTime: Date.now(),
                    });
                    return;
                }
                break;
            case "go-to-previous":
                if (sampleIndex !== 0) {
                    onChangeSingleSampleDataset({
                        ...dataset,
                        samples: [dataset.samples[sampleIndex - 1]],
                        sampleIndex: sampleIndex - 1,
                        startTime: Date.now(),
                    });
                    return;
                }
                break;
            default:
                break;
        }
        onChangeSingleSampleDataset(null);
    };

    const saveSampleLabel = async (sampleIndex, sample, output) => {
        let newDataset = dataset;

        const currentSampleAnnotation = getIn(newDataset, [
            "samples",
            sampleIndex,
            "annotation",
        ]);
        console.log(
            "🚀 ~ saveSampleLabel ~ currentSampleAnnotation:",
            currentSampleAnnotation
        );

        console.log("🚀 ~ saveSampleLabel ~ output:", output);
        if (currentSampleAnnotation === output) {
            console.log("return guard");
            return;
        }

        try {
            if (output === undefined) {
                newDataset = unLabelSample(sampleIndex, dataset);
            } else {
                newDataset = setIn(
                    newDataset,
                    ["samples", sampleIndex, "annotation"],
                    output
                );
            }

            if (
                sample.brush !== selectedBrush &&
                !(sample.brush === undefined && selectedBrush === "complete")
            ) {
                newDataset = setIn(
                    newDataset,
                    ["samples", sampleIndex, "brush"],
                    selectedBrush
                );
            }

            onChangeSingleSampleDataset(
                setIn(singleSampleDataset, ["samples", 0, "annotation"], output)
            );

            loadingContext.setLoading(true);
            // ENABLE IMAGE POINTS
            // if (!newDataset.samples[sampleIndex].pointConsumed) {
            //     // TODO: Handle insufficient points error
            //     const newImagePointsTotal = await consumeImagePoints(user.uid, 1);
            //     newDataset = setIn(
            //         newDataset,
            //         ["samples", sampleIndex, "pointConsumed"],
            //         true);
            //     // Update the image points counter
            //     user.imagePointDetails.total = newImagePointsTotal;
            // }

            onChangeDataset(newDataset);
            const status = { ...projectStatus, samples: newDataset.samples };
            await saveProjectStatus(status);
            loadingContext.setLoading(false);
        } catch (err) {
            loadingContext.setLoading(false);
            // ENABLE IMAGE POINTS
            // if (err.message === "not_enough_image_points") {
            //     setInsufficientImagePointsError(true);
            //     onChangeSingleSampleDataset({
            //         ...dataset,
            //         samples: [dataset.samples[sampleIndex]],
            //         sampleIndex: sampleIndex,
            //         startTime: Date.now(),
            //     });
            // }
            console.error(err);
        }
    };

    return (
    <>
        <MySnackBar
            open={projectExported}
            setOpen={setProjectExported}
            message="Project is Exported"
            severity="info"
            hideDuration={3000}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        />

        <MySnackBar
            open={unlabeledImagesError}
            setOpen={setUnlabeledImagesError}
            message="Some images are unlabeled.  Please label or remove them first."
            severity="error"
            hideDuration={3000}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        />

        <ErrorDialog
            open={invalidProject}
            setOpen={setInvalidProject}
            title="Minimum samples per Label"
            content="to start training Minimum samples per label should be 10"
        />

        {singleSampleDataset ? (
            <>
            {/* <LabelErrorBoundary> */}
            <UniversalDataViewer
                datasetName={`Sample ${singleSampleDataset.sampleIndex}`}
                onSaveTaskOutputItem={async (relativeIndex, output) => {
                    const {
                        sampleIndex,
                        samples: [sample],
                    } = singleSampleDataset;

                    return await saveSampleLabel(sampleIndex, sample, output);
                }}
                onExit={onExit}
                dataset={singleSampleDataset}
                numberOfSamples={dataset.samples.length}
                onClickSetup={onClickSetup}
                setBackCallback={setBackCallback}
            />
            {/* </LabelErrorBoundary> */}
            </>
        ) : (
            <>
            <section className={styles.labelViewContainer}>
                <Box>
                    <Box>
                        <Tabs
                            value={currentTab}
                            onChange={(e, newTab) => setTab(newTab)}
                        ></Tabs>
                    </Box>
                    <h1 className={styles.reviewTitle}>Review</h1>
                    <p className={styles.clickCta}>
                        Click on any image to start Labeling
                    </p>
                    <br />
                    <Box flexGrow={1} />
                </Box>
                <Stats
                    stats={[
                        {
                            name: "Labeled Samples",
                            value: countLabeledSamples(),
                        },
                        {
                            name: "Unlabeled Samples",
                            value: countUnlabeledSamples(),
                        },
                        {
                            name: "Samples Number ",
                            value: dataset.samples.length,
                        },
                        {
                            name: "Percent Complete",
                            value: `${Math.floor(percentComplete * 100)}%`,
                        },
                        {
                            name: "Time per Sample",
                            value: timePerSample,
                        },
                        {
                            name: "Estimated Remaining",
                            value: estimatedRemaining,
                        },
                    ]}
                />

                <section className={styles.btnActionsWrapper}>
                    {["Labeling_and_Training"].includes(projectType) && !status && (
                        <button
                            className={styles.startTrainingBtn}
                            onClick={startTraining}
                        >
                            Start Training
                        </button>
                    )}
                    {(dataset.interface.type === "image_segmentation" ||
                        dataset.interface.type ===
                            "image_pixel_segmentation") && (
                        <button
                            className={styles.exportToCocoBtn}
                            onClick={exportToCoco}
                        >
                            Export to YOLO
                        </button>
                    )}
                    <button className={styles.exportBtn} onClick={exportFile}>
                        Export
                    </button>{" "}
                    <br />
                    <br />
                </section>

                <br />
                <br />
                <br />

                <div className={styles.gridReviewHeader}>
                    <h1 className={styles.reviewTitle}>Your Dataset Review</h1>
                    <p className={styles.clickCta}>
                        Click on any image to start Labeling
                    </p>
                </div>

                <Box flexGrow={1}>
                    {currentTab === "label" && (
                        <SampleGrid
                            dataset={dataset}
                            onChangeDataset={onChangeDataset}
                            projectStatus={projectStatus}
                            saveProjectStatus={saveProjectStatus}
                            count={(dataset.samples || []).length}
                            samples={dataset.samples || []}
                            completed={(dataset.samples || []).map((s) =>
                                Boolean(s.annotation)
                            )}
                            onClick={(sampleIndex) => {
                                // posthog.capture("open_sample", {
                                //   interface_type: dataset.interface.type,
                                // })
                                onChangeSingleSampleDataset({
                                    ...dataset,
                                    samples: [dataset.samples[sampleIndex]],
                                    sampleIndex,
                                    startTime: Date.now(),
                                });
                            }}
                        />
                    )}
                    {/* {currentTab === "activelearning" && <ActiveLearningView />}
                    {currentTab === "labelhelp" && <LabelHelpView />} */}
                </Box>
            </section>
            </>
        )}
    </>
    );
};

export default LabelView;
