import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
import { Annotorious } from "@recogito/annotorious/dist/annotorious.min.js";
import { v4 as uuid } from "uuid";
import "@recogito/annotorious/dist/annotorious.min.css";
import BetterPolygon from '@recogito/annotorious-better-polygon/dist/annotorious-better-polygon.js';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { emptyLabel } from "./util";

function createAnnot(tag) {
    return [{ type: "TextualBody", value: tag.id, purpose: "tagging" }];
}

const AnnotoriousComponent = forwardRef((props, ref) => {
    const {
        imageUrl,
        value,
        annotorious,
        setAnnotorious,
        annotations,
        tool,
        setValue,
        labels,
    } = props;

    // Ref to the image DOM element
    const imgEl = useRef();
    const zoomWrapper = useRef(null);

    const [scale, setScale] = useState(1);

    // The current Annotorious instance

    // Current drawing tool name

    // Init Annotorious when the component
    // mounts, and keep the current 'anno'
    // instance in the application state
    useEffect(() => {
        let newAnnotorious = null;

        if (imgEl.current) {
            // Init
            newAnnotorious = new Annotorious({
                image: imgEl.current,
                disableEditor: true,
            });

            // Extend Annotorious with better polygon control
            BetterPolygon(newAnnotorious);

            newAnnotorious.setAnnotations([]);
        }

        // Keep current Annotorious instance in state
        setAnnotorious(newAnnotorious);

        // Cleanup: destroy current instance
        return () => newAnnotorious.destroy();
    }, []);

    useEffect(() => {
        if (annotorious) {
            annotorious.setDrawingTool(tool);
        }
    }, [tool]);

    useEffect(() => {
        if (annotations) {
            annotations.forEach((element) => {
                annotorious.addAnnotation(element);
            });
        }
    }, [annotations]);

    useEffect(() => {
        if (annotorious) {
            annotorious.off("createSelection");
            annotorious.on("createSelection", async (selection) => {
                const annotation = {
                    ...selection,
                    id: `#${uuid()}`,
                    body: createAnnot(emptyLabel),
                    type: "Annotation",
                };
                annotorious.addAnnotation(annotation);
                annotorious.selectAnnotation(annotation);
                setValue(emptyLabel);
            });
            annotorious.off("selectAnnotation");
            annotorious.on("selectAnnotation", (selection) => {
                const label = labels.find((label) => label.id === selection.body[0].value);
                setValue(label);
            });
            annotorious.off("cancelSelected");
            annotorious.on("cancelSelected", () => {
                setValue(emptyLabel);
            });
        }
    }, [value, annotorious]);

    useEffect(() => {
        if (annotorious) annotorious.setImageScale(scale);
    }, [annotorious, scale]);

    const zoomIn = () => {
        if (zoomWrapper.current) zoomWrapper.current.zoomIn();
    };

    const zoomOut = () => {
        if (zoomWrapper.current) zoomWrapper.current.zoomOut();
    };

    useImperativeHandle(ref, () => ({
        zoomIn,
        zoomOut,
    }));

    // Hacky way to get zoom value because TransformWrapper's onZoom doesn't fire on every animation frame.
    const setZoomWrapperRef = useCallback(node => {
        if (zoomWrapper.current) return;
        if (!node) return;

        if (node && node.instance && node.instance.wrapperComponent) {
            const transformComponentEl = node.instance.wrapperComponent.querySelector(".react-transform-component");
            if (transformComponentEl) {
                const observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutationRecord) {
                        // Find the "scale()" substring in style.transform;
                        const transformValue = transformComponentEl.style.transform;
                        const scaleStartIndex = transformValue.indexOf("scale(") + "scale(".length;
                        const scaleEndIndex = transformValue.indexOf(")", scaleStartIndex);
                        const scaleValue = parseFloat(transformValue.substring(scaleStartIndex, scaleEndIndex));
                        setScale(scaleValue);
                    });
                });
                
                observer.observe(transformComponentEl, { attributes : true, attributeFilter : ['style'] });
                zoomWrapper.current = node;
            }
        }
    });

    return (
        <div>
            <TransformWrapper
                ref={setZoomWrapperRef}
                panning={{activationKeys: ["Shift"]}}
            >
                <TransformComponent
                    wrapperStyle={{
                        width: "100%",
                        height: "100%"
                    }}
                    contentStyle={{
                        width: "100%",
                        height: "100%"
                    }}>
                    <div
                        className="detection-img-container"
                    >
                        <img ref={imgEl} className="detection-img" src={imageUrl} alt="To label" />
                    </div>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
});

export default AnnotoriousComponent;
