import { styled } from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";

export const Container = styled("div")({
    padding: 16,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
});

export const SampleCounter = styled("div")({
    fontSize: 14,
    color: colors.grey[600],
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexGrow: 1,
});
