import AES from "crypto-js/aes"
import { enc } from "crypto-js"
export function getQueryParams() {
  try {
    let q = window.location.href?.split("q=")[1]
    let bytes = AES.decrypt(q, "tubaR")
    let { uid, id: pid, type } = JSON.parse(bytes.toString(enc.Utf8))

    return { pid: pid, uid: uid, type: type }
  } catch (e) {
    return false
  }
}
