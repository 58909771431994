// @flow weak
import React, { useState, useEffect } from "react";
import urlJoin from "url-join";

import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { templates } from "../StartingPage/templates";
import ConfigureImageSegmentation from "../ConfigureImageSegmentation";
import ConfigureImagePixelSegmentation from "../ConfigureImagePixelSegmentation";
import ConfigureImageClassification from "../ConfigureImageClassification";
import { rtdb } from "../../firebase/firebase";
import { getQueryParams } from "../../utils/getQueryParams";
import NavStrip from "./NavStrip";
import { BigTaskButton } from "./Styled";
import { Container } from "../Styled"; // Confusing?

import styles from "./configure-home.module.css"
// interfacePage render the cur component
export const ConfigureInterface = ({
    /**
     * represent the selected labeling
     * type {classification, detection , segmentation}
     *
     * bellow = syntax is true because we are not building a new
     * object but we are destructing an object and defining default value.
     * */
    iface = {},
    projectStatus,
    onChange,
    isNested = false,
}) => {
    const [loading, setLoading] = useState(true);
    const [exists, setExists] = useState(false);
    const [classificationType, setClassificationType] = useState();
    const [savedLabels, setSavedLabels] = useState([]);
    const [advancedConfig, setAdvancedConfig] = useState();
    const [sideBarDesc, setSideBarDesc] = useState([]);
    // this function have role on changing the render
    const { uid, pid } = getQueryParams();

    async function setProjectData(projectStatus) {
        if (projectStatus.iface) {
            // Set classification type
            if (projectStatus.iface.type) {
                setClassificationType(projectStatus.iface.type);
            } else {
                setClassificationType(null);
            }

            // Set labels
            if (projectStatus.iface.labels) {
                setSavedLabels(projectStatus.iface.labels);
            }

            // Set advanced config
            if (projectStatus.iface.multiple) {
                setAdvancedConfig(projectStatus.iface.multiple);
            }

            // Will have value on image segmentation
            if (projectStatus.iface.description) {
                setSideBarDesc(projectStatus.iface.description);
            }
        }
    }

    const changeInterface = (iface) => {
        if (!iface.type) setClassificationType(null);
        else setClassificationType(iface.type);
        onChange(iface);
    };

    const loadProject = async () => {
        // Load projects
        setExists(false);
        setLoading(true);

        try {
            if (uid) {
                if (projectStatus) {
                    setExists(true);
                    await setProjectData(projectStatus);
                }
                setLoading(false);
            } else {
                window.location.href = urlJoin(process.env.REACT_APP_IAM, "projects");
            }
        } catch (e) {
            console.error(e);
            setExists(false);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadProject();
    }, [projectStatus.mode, projectStatus.iface.type]);

    if (loading) {
        return (
            <center style={{ marginTop: "25%" }}>
                <CircularProgress />
            </center>
        );
    }

    if (!exists) {
        return (
            <center style={{ marginTop: "25%" }}>
                <h1>
                    This project does not exist please return back to tuba and
                    select a project from there!
                </h1>
            </center>
        );
    }

    if (!classificationType || classificationType === "empty") {
        // First page the user sees in the labeling tool
        /** before choosing the type of project we get into here */
        return (
            <Container className={styles.mainWrapper}>
                <div className={styles.createLabelWrapper}>
                    <h1 className={styles.createLabels}>
                        Create Labels
                    </h1>
                    <p className={styles.createlabelDesc}>
                        Choose Labeling Type
                    </p>
                </div>

                <br />


                <div className={styles.optionsParent}>
                    {templates
                        .filter((t) => t.name !== "Empty")
                        .map((template) => (
                            <BigTaskButton
                                className={styles.optionsWrapper}
                                key={template.name}
                                onClick={
                                    () => {
                                        changeInterface(template.dataset.interface);
                                    }
                                }
                            >
                                <div className={styles.optionItem} >
                                    <div className={styles.optionColor}>{template.name}</div>
                                    <div>
                                        <template.Icon className={`bigIcon ${styles.iconColor}`} />
                                    </div>
                                </div>
                            </BigTaskButton>
                        ))}

                </div>
            </Container>
        );
    }

    // After selecting the project type we get into here
    return (
        <Container className={styles.paddingAndMarginReset}>
            <div className={styles.projectTypeWrapper}>
                <h1 className={styles.projectTitle}>Labeling Configuration</h1>
            </div>
            {/* the upper bar of the view */}
            {!classificationType && <NavStrip
                currentlySelected={classificationType}
                onChange={(type) => {
                    /**
                     * this function should help the user
                     * to change the labelling type from within a label type
                     */
                    changeInterface(
                        templates
                            .filter((t) => t.name !== "Empty")
                            .map((t) => t.dataset.interface)
                            .find((t) => t.type === type) || {},
                    );
                }}
            />}

            {/* WAS  */}
            {/* <Grid container>
                <Grid item xs={12} lg={isNested ? 12 : 6}> */}

            {/* NOW */}
            <Grid >
                <Grid >
                    <Box paddingTop={2} />

                    {/** render object detection form */}
                    {classificationType === "image_segmentation" && (
                        <ConfigureImageSegmentation
                            onChange={changeInterface}
                            projectStatus={projectStatus}
                            iface={iface}
                            user_id={uid}
                            project_id={pid}
                            savedLabels={savedLabels}
                        />
                    )}

                    {/** render image segentation form */}
                    {/* {classificationType === "image_pixel_segmentation" && (
                    <ConfigureImagePixelSegmentation
                        iface={iface}
                        projectStatus={projectStatus}
                        onChange={changeInterface}
                        user_id={uid}
                        project_id={pid}
                        savedLabels={savedLabels}
                        sideBarDesc={sideBarDesc}
                    />
                )} */}
                    {classificationType === "image_pixel_segmentation" && (
                        <ConfigureImageSegmentation
                            onChange={changeInterface}
                            projectStatus={projectStatus}
                            iface={iface}
                            user_id={uid}
                            project_id={pid}
                            savedLabels={savedLabels}
                        />
                    )}
                    {/** render image_classification form */}
                    {classificationType === "image_classification" && (
                        <ConfigureImageClassification
                            iface={iface}
                            projectStatus={projectStatus}
                            onChange={changeInterface}
                            user_id={uid}
                            project_id={pid}
                            savedLabels={savedLabels}
                            advancedConfig={advancedConfig}
                        />
                    )}
                </Grid>
            </Grid>
        </Container>
        // <section className={styles.container}>

        //     <div className={styles.labelingWrapper}>
        //         <h1 className={styles.title} >Classification</h1>
        //     </div>
        //     {/* the upper bar of the view */}
        //    {!classificationType && <NavStrip
        //         currentlySelected={classificationType}
        //         onChange={(type) => {
        //             /**
        //              * this function should help the user
        //              * to change the labelling type from within a label type
        //              */
        //             changeInterface(
        //                 templates
        //                     .filter((t) => t.name !== "Empty")
        //                     .map((t) => t.dataset.interface)
        //                     .find((t) => t.type === type) || {},
        //             );
        //         }}
        //     />}

        //     <Grid container>
        //         <Grid item xs={12} lg={isNested ? 12 : 6}>
        //             <Box paddingTop={2} />

        //             {/** render object detection form */}
        //             {classificationType === "image_segmentation" && (
        //                 <ConfigureImageSegmentation
        //                     onChange={changeInterface}
        //                     projectStatus={projectStatus}
        //                     iface={iface}
        //                     user_id={uid}
        //                     project_id={pid}
        //                     savedLabels={savedLabels}
        //                 />
        //             )}

        //             {/** render image segentation form */}
        //             {/* {classificationType === "image_pixel_segmentation" && (
        //                 <ConfigureImagePixelSegmentation
        //                     iface={iface}
        //                     projectStatus={projectStatus}
        //                     onChange={changeInterface}
        //                     user_id={uid}
        //                     project_id={pid}
        //                     savedLabels={savedLabels}
        //                     sideBarDesc={sideBarDesc}
        //                 />
        //             )} */}
        //             {classificationType === "image_pixel_segmentation" && (
        //                 <ConfigureImageSegmentation
        //                 onChange={changeInterface}
        //                 projectStatus={projectStatus}
        //                 iface={iface}
        //                 user_id={uid}
        //                 project_id={pid}
        //                 savedLabels={savedLabels}
        //             />
        //             )}
        //             {/** render image_classification form */}
        //             {classificationType === "image_classification" && (
        //                 <ConfigureImageClassification
        //                     iface={iface}
        //                     projectStatus={projectStatus}
        //                     onChange={changeInterface}
        //                     user_id={uid}
        //                     project_id={pid}
        //                     savedLabels={savedLabels}
        //                     advancedConfig={advancedConfig}
        //                 />
        //             )}
        //         </Grid>
        //     </Grid>
        // </section>
    );
};

export default ConfigureInterface;
