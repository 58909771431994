import { rtdb } from "./firebase";

export  function saveStatistics(userId, projId, statistics)
{
    try{
        console.log("uid is : ", userId, "projId is : ", projId);
        rtdb.ref(userId + "/" + projId + "/statistics").set(statistics);
    }
    catch(err){
        throw err;
    }
    return true;
}