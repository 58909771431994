// @flow
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import isEmpty from "lodash/isEmpty";
import { useStyles } from "./Styles";

const SAVE_WAIT = 2000;

export default ({ value, onChange }) => {
    const c = useStyles();
    const [{ editing, newValue }, changeEditing] = useState({
        editing: false,
        newValue: value || window.location.href.split("id=")[1],
    });

    useEffect(() => {
        if (!isEmpty(newValue) && newValue !== "unnamed") {
            changeEditing({ editing, newValue });
        } else {
            changeEditing({ editing, newValue: value });
        }
    }, [newValue, editing, value]);

    useEffect(() => {
        if (!editing) return;
        const listener = (e) => {
            if (e.key === "Enter") {
                onChange(newValue);
                changeEditing({ editing: false });
            }
        };
        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        };
    }, [editing, newValue, onChange]);

    useEffect(() => {
        if (!newValue) return;
        if (editing) {
            const timeout = setTimeout(
                () => {
                    onChange(newValue);
                    changeEditing({ editing: false });
                },
                value === newValue ? SAVE_WAIT * 5 : SAVE_WAIT,
            );

            return () => clearTimeout(timeout);
        }
        return () => {};
    }, [editing, newValue, value, onChange]);

    return (
        <TextField
            className={c.textField}
            label="File Name"
            variant="outlined"
            size="small"
            InputProps={{
                inputProps: { style: { color: "#000" } },
            }}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                    e.stopPropagation();
                    e.target.blur();
                }
            }}
            onKeyPress={(e) => {
                e.stopPropagation();
            }}
            onChange={(e) => {
                changeEditing({
                    editing: true,
                    newValue: e.target.value,
                });
            }}
            value={newValue || ""}
        />
    );
};
