import React, { useState, useRef, useEffect } from "react";

import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import SimpleDialog from "../SimpleDialog";
import getSampleFromUrl from "../PasteUrlsDialog/get-sample-from-url";
import MultiFileDrop from "../MultiFileDrop";
import { uploadToS3 } from "../../utils/s3/minio";
import { getQueryParams } from "../../utils/getQueryParams";
import styles from './uploadPop.module.css'
import { getStorageUsage, getSubscriptionDetails } from "../../utils/backend";
import { MySnackBar } from "../helpers/material_ui_helpers/MySnackBar";

export const UploadToS3Dialog = ({ open, onClose, onAddSamples }) => {
    const [complete, setComplete] = useState(false);
    const uploadedPaths = useRef([]);
    const fileSizes = [];
    const queryParams = getQueryParams();

    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [storageLimitExceeded, setStorageLimitExceeded] = useState(false);

    const getSubscription = async () => {
        const subscription = await getSubscriptionDetails(queryParams.uid);
        setSubscriptionDetails(subscription);
    }

    useEffect(() => {
        getSubscription();
    }, []);

    if (!open) return null;
    return (
        subscriptionDetails ? (
            <>
                <MySnackBar
                    open={storageLimitExceeded}
                    setOpen={setStorageLimitExceeded}
                    message={`Your project storage has exceeded ${subscriptionDetails.storage_limit_gb}GB, the limit for your current plan`}
                    severity="error"
                    hideDuration={7000}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                />

                <SimpleDialog
                    onClose={onClose}
                    open={open}
                    title="Upload From Your PC"
                    actions={[
                        {
                            disabled: (!complete || storageLimitExceeded),
                            onClick: () => {
                                const samples = uploadedPaths.current
                                    .map((url) => getSampleFromUrl(url, { returnNulls: true }))
                                    .filter(Boolean);
                                onAddSamples(samples);
                            },
                            text: "Add Samples",
                        },
                    ].filter(Boolean)}
                >
                    {/** theses are childes of SimpleDialog. */}
                    <Stepper className={styles.uploadTitleUpload}>
                        <Step>
                            <StepLabel className={styles.uploadFilesTitle}>Upload Files</StepLabel>

                        </Step>
                    </Stepper>

                    <Box>
                        <MultiFileDrop
                            loadFile={async (file) => {
                                const usage = await getStorageUsage(queryParams.uid);
                                const fileSize = file.size ? file.size : 0;
                                fileSizes.push(fileSize);
                                let totalUploadSize = 0;
                                for (const size of fileSizes) totalUploadSize += size;
                                const updatedUsageGb = (usage.usage_bytes + totalUploadSize)/(2**30)

                                if (subscriptionDetails.storage_limit_gb !== null && updatedUsageGb >= subscriptionDetails.storage_limit_gb)
                                    setStorageLimitExceeded(true);
                                else {
                                    const objectUrl = (
                                        await uploadToS3(file, queryParams.uid, queryParams.pid, file.path));
                                    uploadedPaths.current.push(objectUrl);
                                }
                            }}
                            onComplete={() => {
                                setComplete(true);
                            }}
                        />
                    </Box>
                </SimpleDialog>
            </>
        // TODO: Fix this
        ) : ""
    );
};

export default UploadToS3Dialog;


