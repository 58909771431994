import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function ErrorDialog({open, setOpen, title, content, onApproval, stats}) {

  const handleClose = () => {
    setOpen(false);
  };

  const handleApproval = (e)=>{
      onApproval();
      handleClose();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        
        <DialogContent>  
          <DialogContentText  
                style={{whiteSpace: 'pre-line'}}
                id="alert-dialog-description">  {/** allow new line character "\n" to make effect */}
            {content}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>

      </Dialog>
    </div>
  );
}


export  {ErrorDialog};
