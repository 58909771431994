import React from "react";

import { templates } from "../StartingPage/templates";
import { NavStripButton } from "../Styled";

const NavStrip = ({ currentlySelected, onChange }) => {
    const buttons = templates
        .filter((t) => t.name !== "Empty")
        .map((t) => (
            <NavStripButton
                disabled
                key={t.dataset.interface.type || "Back"}
                className={
                    currentlySelected === t.dataset.interface.type
                        ? "selected"
                        : ""
                }
                variant="outlined"
                onClick={() => {
                    if (currentlySelected !== t.dataset.interface.type) {
                        onChange(t.dataset.interface.type);
                    }
                }}
            >
                <t.Icon className="icon" />
                {t.name}
            </NavStripButton>
        ));
    return buttons;
};

export default NavStrip;
