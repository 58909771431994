// @flow
import React, { useMemo, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Survey from "material-survey/components/Survey";
import { setIn } from "seamless-immutable";

import { rtdb } from "../../firebase/firebase";
import { Container } from "../Styled";
import styles from "./segmentation.module.css"

const autoSegmentationOptions = {
    simple: { type: "simple" },
    autoseg: { type: "autoseg" },
};

const form = {
    questions: [
        {
            name: "labels",
            title: "Add Labels",
            description:
                "If you're labeling regions on an image, these are the allowed classifications or tags.",
            type: "matrixdynamic",
            columns: [
                { cellType: "text", name: "id", title: "Labels" },
                {
                    cellType: "text",
                    name: "description",
                    title: "Description (optional)",
                },
            ],
        },
        {
            name: "description",
            title: "Sidebar Description",
            description: "Any instructions or notes in markdown.",
            type: "text",
        },
    ],
};

export const ConfigureImagePixelSegmentation = ({
    iface,
    projectStatus,
    onChange,
    userId,
    projectId,
    savedLabels,
    sideBarDesc,
}) => {
    const projectRef = rtdb.ref(`${userId}/${projectId}`);
    projectRef.child("classification_type").set("image_pixel_segmentation");

    useEffect(() => {
        let status = setIn(projectStatus, ["iface", "labels"], savedLabels);
        status = setIn(status, ["iface", "description"], sideBarDesc);
        onChange(status);
    }, []);

    const saveLabelsAndChoice = (myLabels, descr) => {
        const myRealLabels = [];
        if (myLabels) {
            for (const val of myLabels) {
                if (val.id && val.id.trim() !== "") {
                    myRealLabels.push({
                        id: val.id.trim(),
                        description: val?.description ? val.description : "",
                    });
                }
            }
        }
        try {
            if (myRealLabels.length) {
                projectRef.child("labels").set(myRealLabels);
                setIn(iface, ["labels"], myRealLabels);
            }
        } catch (err) {
            console.error("Can't save labels to database, error: ", err);
        }
        try {
            if (descr !== undefined) {
                projectRef.child("sideBarDesc").set(descr);
                setIn(iface, ["description"], descr);
            }
        } catch (err) {
            console.error(
                "Can't save advanced configuration to database, error: ",
                err,
            );
        }

        window.location.reload(false);
    };

    const defaultAnswers = useMemo(
        () => ({
            description: sideBarDesc,
            labels:
                (savedLabels || []).map((a) => (typeof a === "string" ? { id: a, description: a } : a)) || [],
            // autoseg: iface.autoSegmentationEngine?.type,
        }),
        [savedLabels, sideBarDesc],
    );
    return (
        <Container className={styles.containerWrapp} >
            <Survey
                noActions
                variant="flat"
                defaultAnswers={defaultAnswers}
                onQuestionChange={(questionId, newValue, answers) => {
                    if (questionId === "autoseg") {
                        onChange(
                            setIn(
                                iface,
                                ["autoSegmentationEngine"],
                                autoSegmentationOptions[newValue],
                            ),
                        );
                    } else {
                        let filteredNewValues = [];

                        if (questionId === "labels") {
                            for (const val of newValue) {
                                if (val.id && val.id.trim() !== "") {
                                    filteredNewValues.push({
                                        id: val.id.trim(),
                                        description: val?.description?.trim(),
                                    });
                                }
                            }
                        } else filteredNewValues = newValue;

                        onChange(setIn(iface, [questionId], filteredNewValues));
                    }
                }}
                form={form}
            />
            <Button
                variant="contained"
                size="large"
                color="Success"
                onClick={() => {
                    saveLabelsAndChoice(iface.labels, iface.description);
                }}
            >
                Save
            </Button>
        </Container>
    );
};

export default ConfigureImagePixelSegmentation;
