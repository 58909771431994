import firebase from "firebase/app";
import "firebase/firestore";
//import "firebase/auth";
//import "firebase/firestore";
import "firebase/database";

const firebaseConfig = require(`./${process.env.REACT_APP_FIREBASE_CONFIG_RELPATH}`);

firebase.initializeApp(firebaseConfig)
//export const auth = firebase.auth();
//export const firestore = firebase.firestore();
export const rtdb = firebase.database();
export const firestore = firebase.firestore();
